import request from '../request'
import proxy from '../proxy'
import constants from '../../helpers/constants'

export default {
  getSimulaciones: function (data) {
    return request.get('simulacion', { params: data })
  },

  addSimulacion: function (data) {
    return request.post('simulacion', data)
  },

  deleteSimulacionBDD: function (idSimulacion) {
    return request.delete(`simulacion/${idSimulacion}`)
  },

  simulate: function (datosSimulador) {
    return proxy.post(constants.urlSimulacion, 'simulate', datosSimulador)
  },

  getMeteo: function (params) {
    let paramsUrl = ''
    for (const property in params) {
      paramsUrl += `${property}=${params[property]}&`
    }
    paramsUrl = paramsUrl.slice(0, -1)

    return proxy.get(constants.urlSimulacion, `getMeteoPunto?${paramsUrl}`)
  },

  getSimulacion: function (idSimulacion, epsg) {
    return proxy.get(constants.urlSimulacion, `result_json/${idSimulacion}/${epsg}`)
  },

  getJsonWindNinja: function (idSimulacion, fecha) {
    return proxy.get(constants.urlSimulacion, `devolverJsonWindNinja/${idSimulacion}/${fecha}`)
  },

  getFlameLength: function (idSimulacion, epsg) {
    return proxy.get(constants.urlSimulacion, `getFlameLength/${idSimulacion}/${epsg}`)
  },

  getIntensity: function (idSimulacion, epsg) {
    return proxy.get(constants.urlSimulacion, `getIntensity/${idSimulacion}/${epsg}`)
  },

  getSpreadRate: function (idSimulacion, epsg) {
    return proxy.get(constants.urlSimulacion, `getSpreadRate/${idSimulacion}/${epsg}`)
  },

  getInformeSimulacion: function (idSimulacion) {
    return proxy.get(constants.urlSimulacion, `report_meteo/${idSimulacion}`)
  },

  deleteSimulacionAPI: function (idSimulacion) {
    return proxy.delete(constants.urlSimulacion, `removeSimulation/${idSimulacion}`)
  }
}
