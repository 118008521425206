<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Añadir medio</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-form v-model="isValid" @submit.prevent="aceptar">
            <v-row>
              <v-col>
                <v-text-field label="Medio" :value="medioSelected? medioSelected.MEDIO : ''" disabled outlined hide-details />
              </v-col>
            </v-row>

            <v-row>
              <!-- <v-col>
                <v-select v-model="sectorSelected" :items="sectores" label="Sector" outlined item-text="SECTOR" item-value="ID_SECTOR" return-object hide-details :rules="[globalRules.required]" />
              </v-col> -->

              <v-col>
                <vx-date-text-edit v-model="horaSalidaBase" label="Hora salida base" outlined :min="horaSalidaMin" :max="horaSalidaMax" required />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field v-model="horasOperativo" label="Tiempo operativo (horas)" type="number" :min="1" outlined dense :rules="[globalRules.required]" />
              </v-col>
              <v-col>
                <vx-time-text-edit label="Tiempo de descanso" v-model="horasDescanso" outlined hide-details :min="descansoMinimo" />
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col>
                <v-switch v-model="vueltaAgoncillo" label="Vuelta a Agoncillo"/>
              </v-col>
            </v-row> -->
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green darken-1" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import constants from '../../helpers/constants'

export default {
  props: {
    show: Boolean,
    idIncendio: String,
    horaSalidaMin: String,
    horaSalidaMax: String,
    medioSelected: Object
  },

  data: () => ({
    isValid: false,

    // vueltaAgoncillo: false,
    // sectorSelected: null,

    horaSalidaBase: null,
    horasOperativo: 17,
    horasDescanso: null
  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    descansoMinimo () {
      let t = this.medioSelected

      if (!t) return '00:00'

      let tiempoVuelo = t.TIEMPO_MAX_VUELO
      let descanso = Math.ceil(tiempoVuelo / 60) * 20 // 20 min x hora de vuelo

      if (tiempoVuelo > 2 * 60) { // Si ha volado + de su maximo (2h), solo descansa 40 min
        descanso = 40
      } else if (descanso < 10) { // Si le corresponden - de 10 min de descanso, descansa 10 min
        descanso = 10
      }

      let n = new Date(0, 0)
      n.setSeconds(descanso * 60)

      return n.toTimeString().slice(0, 5)
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.horaSalidaBase = this.$date.currentDate()

        this.horasDescanso = this.descansoMinimo
      }
    }
  },

  methods: {
    cancelar () {
      this.$emit('cancelar')
    },

    aceptar () {
      let minutosDescanso = this.horasDescanso.split(':')[0] * 60 + parseInt(this.horasDescanso.split(':')[1])

      let dataMedio = {
        MEDIO: this.medioSelected,
        AGONCILLO: false, // this.vueltaAgoncillo,
        HORA_SALIDA_BASE: this.$date.parseDate(this.horaSalidaBase).add(2, 'hours'),
        TIEMPO_OPERATIVO: this.horasOperativo,
        TIEMPO_DESCANSO: minutosDescanso
      }

      let medioSector
      let mediosIncendio = {
        ID_INCENDIO: this.idIncendio,
        MEDIOS: []
      }

      medioSector = {
        ID_MEDIO_SECTOR: this.$uuid.createUUID(),
        ID_MEDIO: this.medioSelected.ID_MEDIO,
        FECHA_ALARMADO: this.$date.parseDate(this.horaSalidaBase)
      }

      // TODO: !!!! planificacion!!!!!

      mediosIncendio.MEDIOS.push(medioSector)

      let data = {
        dataMedio: dataMedio,
        mediosIncendio: mediosIncendio
      }

      this.$emit('aceptar', data)
    }
  }
}
</script>

<style scoped>
</style>
