<template>
  <div>
    <v-dialog v-model="show" persistent max-width="800px">
      <v-card>
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Editar sectores</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col class="pa-8">
              <v-data-table :headers="headersLeft" :items="mediosLeftTable" disable-pagination hide-default-footer fixed-header height="540px" style="direction: rtl;">
                <template #no-data>
                  <p>No hay medios asignados.</p>
                </template>
                <template #top>
                  <v-select v-model="leftSector" :items="sectores.filter((x) => x.ID_SECTOR !== rightSector)" prepend-icon="esri-icon-description" label="Sector" item-text="SECTOR" item-value="ID_SECTOR" single-line autocomplete />
                </template>
                <template #item="{ item }">
                  <tr :style="rowColor(item)">
                    <td align="right">
                      <!-- Editar -->
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <v-btn :disabled="leftSector === rightSector || !rightSector" fab icon small v-bind="attrs" v-on="on" @click="editMedio(item, 'left')">
                            <v-icon color="black">mdi-arrow-right</v-icon>
                          </v-btn>
                        </template>
                        <span>Cambiar de sector</span>
                      </v-tooltip>
                    </td>
                    <td align="left">
                      {{ item.MEDIO }} <span v-show="isMedioFueraHorario(item.ID_MEDIO)" class="font-weight-black">*</span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col class="pa-8">
              <v-data-table :headers="headersRight" :items="mediosRightTable" disable-pagination hide-default-footer fixed-header height="540px">
                <template #no-data>
                  <p>No hay medios asignados.</p>
                </template>

                <template #top>
                  <v-select v-model="rightSector" :items="sectores.filter((x) => x.ID_SECTOR !== leftSector)" prepend-icon="esri-icon-description" label="Sector" item-text="SECTOR" item-value="ID_SECTOR" single-line autocomplete />
                </template>

                <template #item="{ item }">
                  <tr :style="rowColor(item)">
                    <td align="left">
                      <v-layout>
                        <!-- Editar -->
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn :disabled="leftSector === rightSector || !leftSector" fab icon small v-bind="attrs" v-on="on" @click="editMedio(item, 'right')">
                              <v-icon color="black">mdi-arrow-left</v-icon>
                            </v-btn>
                          </template>
                          <span>Cambiar de sector</span>
                        </v-tooltip>
                      </v-layout>
                    </td>
                    <td align="right">
                      {{ item.MEDIO }} <span v-show="isMedioFueraHorario(item.ID_MEDIO)" class="font-weight-black">*</span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <!-- <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
          <v-btn text color="green" @click="aceptar">Aceptar</v-btn> -->
          <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import constants from '../../../helpers/constants'
import * as ArcGIS from '../../../helpers/ArcGIS'

export default {

  props: {
    show: Boolean,
    sectoresSelected: Array,
    idIncendio: String,
    medios: Array
  },

  data: () => ({
    headersLeft: [
      { text: '', value: 'ACCIONES', align: 'right', sortable: false },
      { text: 'Medio', value: 'MEDIO', align: 'left', sortable: true }
    ],
    headersRight: [
      { text: '', value: 'ACCIONES', align: 'left', sortable: false },
      { text: 'Medio', value: 'MEDIO', align: 'right', sortable: true }

    ],

    estadoMedios: [],
    medioSelected: {},
    isValid: false,
    horasDescanso: 10,
    leftSector: null,
    rightSector: null
  }),

  computed: {
    allMedios () {
      return this.$store.getters['medio/medios']
    },

    mediosOrdered () {
      let medios = []

      if (this.medios) {
        medios = this.orderMedios()
      }

      return medios
    },

    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    sectores () {
      let sectores = []

      if (this.incendio) {
        sectores = this.incendio.SECTORES
      }

      return sectores
    },

    mediosLeftTable () {
      let medioSector = {}

      let mediosTable = []

      let sectorSelected = this.sectores.find(x => x.ID_SECTOR === this.leftSector)

      if (this.leftSector === null || !sectorSelected) return []

      // Recorro los medios del sector
      for (let j = 0; j < sectorSelected.MEDIOS.length; j++) {
        let medioSectorSelected = sectorSelected.MEDIOS[j]

        for (let k = 0; k < this.medios.length; k++) {
          let medio = this.medios[k]

          if (medioSectorSelected.ID_MEDIO === medio.ID_MEDIO && medioSectorSelected.FECHA_AVISO && medio.ID_SECTOR === this.leftSector) { // Solo muestra los REAL, no los PLANIF
            medioSector = {
              ID_INCENDIO: this.idIncendio,
              ID_SECTOR: medioSectorSelected.ID_SECTOR,
              SECTOR: sectorSelected.SECTOR,
              ID_MEDIO_SECTOR: medioSectorSelected.ID_MEDIO_SECTOR,
              ID_MEDIO: medio.ID_MEDIO,
              MEDIO: medio.MEDIO,
              ESTADO: medio.ESTADO,
              FECHA_ALARMADO: medioSectorSelected.FECHA_ALARMADO,
              FECHA_EN_CAMINO: medioSectorSelected.FECHA_EN_CAMINO,
              FECHA_EN_LUGAR: medioSectorSelected.FECHA_EN_LUGAR,
              FECHA_FINALIZADO: medioSectorSelected.FECHA_FINALIZADO,
              FECHA_LLEGADA_GEOFENCING: medioSectorSelected.FECHA_LLEGADA_GEOFENCING,
              FECHA_FIN_DESCANSO: medioSectorSelected.FECHA_FIN_DESCANSO,
              NUM_COMPONENTES: medioSectorSelected.NUM_COMPONENTES,
              PLANIFICACION: medioSectorSelected.PLANIFICACION
            }
            mediosTable.push(medioSector)
          }
        }
      }

      return mediosTable
    },

    mediosRightTable () {
      let medioSector = {}

      let mediosTable = []

      let sectorSelected = this.sectores.find(x => x.ID_SECTOR === this.rightSector)

      if (this.rightSector === null || !sectorSelected) return []

      // Recorro los medios del sector
      for (let j = 0; j < sectorSelected.MEDIOS.length; j++) {
        let medioSectorSelected = sectorSelected.MEDIOS[j]

        for (let k = 0; k < this.medios.length; k++) {
          let medio = this.medios[k]

          if (medioSectorSelected.ID_MEDIO === medio.ID_MEDIO && medioSectorSelected.FECHA_AVISO && medio.ID_SECTOR === this.rightSector) { // Solo muestra los REAL, no los PLANIF
            medioSector = {
              ID_INCENDIO: this.idIncendio,
              ID_SECTOR: medioSectorSelected.ID_SECTOR,
              SECTOR: sectorSelected.SECTOR,
              ID_MEDIO_SECTOR: medioSectorSelected.ID_MEDIO_SECTOR,
              ID_MEDIO: medio.ID_MEDIO,
              MEDIO: medio.MEDIO,
              ESTADO: medio.ESTADO,
              FECHA_ALARMADO: medioSectorSelected.FECHA_ALARMADO,
              FECHA_EN_CAMINO: medioSectorSelected.FECHA_EN_CAMINO,
              FECHA_EN_LUGAR: medioSectorSelected.FECHA_EN_LUGAR,
              FECHA_FINALIZADO: medioSectorSelected.FECHA_FINALIZADO,
              FECHA_LLEGADA_GEOFENCING: medioSectorSelected.FECHA_LLEGADA_GEOFENCING,
              FECHA_FIN_DESCANSO: medioSectorSelected.FECHA_FIN_DESCANSO,
              NUM_COMPONENTES: medioSectorSelected.NUM_COMPONENTES,
              PLANIFICACION: medioSectorSelected.PLANIFICACION
            }

            mediosTable.push(medioSector)
          }
        }
      }

      return mediosTable
    }

  },

  watch: {

    sectores: {
      handler: function (sectors) {
        if (sectors.length > 0) {
          this.leftSector = this.leftSector ? this.leftSector : sectors[0].ID_SECTOR

          if (sectors.length > 1) {
            this.rightSector = this.rightSector ? this.rightSector : sectors[1].ID_SECTOR
          }
        }
      }
    },

    show: {
      handler: function () {
        if (this.sectores.length > 0) {
          this.leftSector = this.sectores[0].ID_SECTOR

          if (this.sectores.length > 1) {
            this.rightSector = this.sectores[1].ID_SECTOR
          }
        }
      }
    }
  },

  methods: {
    rowColor (item) { // TODO: esto hay que rehacerlo
      let backgroundColor = this.estadoMedios[1].COLOR // Azul
      let borderColor = this.estadoMedios[1].COLOR

      if (item.FECHA_FINALIZADO) {
        backgroundColor = '#CCC8C5'
        borderColor = '#CCC8C5'
      } else if (item.FECHA_EN_LUGAR) {
        backgroundColor = this.estadoMedios[3].COLOR // Amarillo
        borderColor = this.estadoMedios[3].COLOR
      } else if (item.FECHA_EN_CAMINO) {
        backgroundColor = this.estadoMedios[2].COLOR // Rojo
        borderColor = this.estadoMedios[2].COLOR
      }

      // if (!item.FECHA_EN_CAMINO) {
      //   backgroundColor = this.estadoMedios[1].COLOR // Azul
      //   borderColor = this.estadoMedios[1].COLOR
      // } else if (!item.FECHA_EN_LUGAR) {
      //   backgroundColor = this.estadoMedios[2].COLOR // Rojo
      //   borderColor = this.estadoMedios[2].COLOR
      // } else if (!item.FECHA_FINALIZADO) {
      //   backgroundColor = this.estadoMedios[3].COLOR // Amarillo
      //   borderColor = this.estadoMedios[3].COLOR
      // }

      return {
        'background-color': backgroundColor,
        'border-color': borderColor
      }
    },

    isMedioFueraHorario (idMedio) {
      let isMedioFueraHorario = false

      let medio = this.allMedios.find(x => x.ID_MEDIO === idMedio)
      if (medio) {
        let horaSalidaMedio = this.$date.parseDate(medio.HORA_SALIDA, 'HH:mm').format('HH:mm')
        let horaActual = this.$date.formatDate(this.$date.now(), 'HH:mm')
        isMedioFueraHorario = horaActual > horaSalidaMedio
      }
      return isMedioFueraHorario
    },

    orderMedios () {
      let order = JSON.parse(JSON.stringify(this.medios))

      let mediosOrder = order.sort((a, b) => {
        let aEstado = a.ESTADO
        let bEstado = b.ESTADO
        let aMedio = a.MEDIO.toUpperCase()
        let bMedio = b.MEDIO.toUpperCase()

        if (aEstado === bEstado) {
          return (aMedio < bMedio) ? -1 : (aMedio > bMedio) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      return mediosOrder
    },

    editMedio (medio, lado) {
      medio.ID_SECTOR = lado === 'left' ? this.rightSector : this.leftSector

      this.$emit('acceptEditSectorIncendio', medio)
    },

    zoomMedio (idMedio) {
      let medio = this.allMedios.find(x => x.ID_MEDIO === idMedio)
      if (medio && medio.ULT_POSICION) {
        let center = {
          target: [medio.ULT_POSICION.LONGITUD, medio.ULT_POSICION.LATITUD],
          zoom: 17
        }

        ArcGIS.setCenterMap(center)
      }
    },

    cerrar () {
      this.$emit('close')
    }
  },

  created () {
    this.estadoMedios = constants.estadosMedio
  }

}
</script>

<style scoped>
</style>
