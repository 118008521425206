<template>
  <v-container id="incendiosList" :class="{'mobile': $vuetify.breakpoint.xs}">
    <v-row dense v-for="(incendio, i) in incendiosOrder" :key="i">
      <v-col>
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-card v-bind="attrs" v-on="on" :color="incendio.NIVELES.length>0 && !incendio.NIVELES[0].FECHA_FIN ? incendio.NIVELES[0].TIPO_INCENDIO_NIVEL.COLOR: incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.COLOR" elevation="6" dark @click="selectIncendio(incendio)">
              <v-card-title class="text-h7 pt-0 pl-3">
                {{ incendio.NOMBRE ? incendio.NOMBRE : incendio.LOCALIDAD }}
                <v-spacer />

                <v-btn class="mx-0" icon v-show="(incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE === 32 || incendio.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE === 50)" @click.stop.prevent="ocultarIncendio(incendio)">
                  <v-icon color="black">mdi-eye-off</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-subtitle :class="{'text-right': $vuetify.breakpoint.smAndUp, 'pb-0': true}">
                {{ incendio.ESTADOS[0].FECHA | formatDate }}
              </v-card-subtitle>
            </v-card>
          </template>
          <span>Detalles incendio</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    show: false
  }),

  computed: {
    ...mapGetters('incendio', [
      'incendios'
    ]),

    incendiosOrder () {
      return this.orderIncendios()
    }
  },

  methods: {
    selectIncendio (incendio) {
      this.$emit('selectIncendio', incendio.ID_INCENDIO)
    },

    ocultarIncendio (incendio) {
      this.$store.dispatch('incendio/ocultarIncendio', {
        ID_INCENDIO: incendio.ID_INCENDIO,
        OCULTO: true
      })
    },

    orderIncendios () {
      let orderIncendios = JSON.parse(JSON.stringify(this.incendios))
      orderIncendios.sort((a, b) => {
        let aEstado = a.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE
        let bEstado = b.ESTADOS[0].TIPO_INCENDIO_ESTADO.VALUE
        let aFecha = a.FECHA_CREACION
        let bFecha = b.FECHA_CREACION

        if (aEstado === bEstado) {
          return (aFecha > bFecha) ? -1 : (aFecha < bFecha) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      return orderIncendios
    }
  },

  mounted () {
    this.orderIncendios()
  }
}
</script>

<style scoped>
  #incendiosList {
    position: absolute;
    top: 20px;
    left: 15px;
    overflow-y: auto;
    width: 300px;
    max-height: calc(100% - 100px);
  }

  .mobile {
    top: 65px !important;
    width: 170px !important;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }
</style>
