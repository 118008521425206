<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Nivel del incendio</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form v-model="valid">
          <v-container fluid>
            <!-- Aviso -->
            <v-row>
              <v-col sm="10">
                <v-select
                  :items="tiposIncendioNivel" disabled item-text="INCENDIO_NIVEL"
                  v-model="dataModified.TIPO_INCENDIO_NIVEL.INCENDIO_NIVEL" label="Nivel del incendio" outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="10">
                <vx-date-text-edit
                  v-model="dataModified.FECHA_INICIO" :min="fechaInicioMin"
                  label="Fecha de inicio" not-future outlined required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="10">
                <vx-date-text-edit
                  v-model="dataModified.FECHA_FIN" :min="fechaFinMin" :max="fechaFinMax"
                  label="Fecha de fin" not-future outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form> <!-- Llegada -->
      </v-card-text>

      <v-card-actions>
        <v-btn text color="red" @click="close">Cancelar</v-btn>
        <v-btn text color="green" :disabled="acceptDisable" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
  props: {
    show: Boolean,
    idIncendio: String,
    dataNivel: Object
  },

  data: () => ({
    dataModified: null,
    valid: true,
    nivelesPosibles: ['Nivel 1', 'Nivel 2'],
    estadoIncendio: null

  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },
    ...mapGetters('incendio', [
      'tiposIncendioNivel'
    ]),
    fechaFinMax () {
      let fechaMin = null
      if (this.incendio && this.dataModified && this.dataNivel) {
        if (this.dataModified.index !== 0) {
          fechaMin = this.$date.parseDate(this.incendio.NIVELES[this.dataNivel.index - 1].FECHA_INICIO)
        }
      }
      return fechaMin
    },
    fechaInicioMax () {
      let fechaMin = null
      if (this.incendio && this.dataModified && this.dataNivel) {
        if (this.dataModified.index !== 0) {
          fechaMin = this.incendio.NIVELES[this.dataNivel.index - 1].FECHA_INICIO
        }
      }
      return fechaMin
    },
    fechaInicioMin () {
      let fechaMin = null

      if (this.incendio && this.dataNivel) {
        if (this.incendio.NIVELES.length === 0 || this.incendio.NIVELES.length === this.dataModified.index + 1) {
          fechaMin = this.$date.parseDate(this.incendio.ESTADOS[this.incendio.ESTADOS.length - 1].FECHA)
        } else {
          fechaMin = this.$date.parseDate(this.incendio.NIVELES[this.dataNivel.index + 1].FECHA_FIN)
        }
      }

      return fechaMin
    },
    fechaFinMin () {
      let fechaMin = null
      let fechaInicio = null

      if (this.incendio && this.dataModified && this.dataNivel) {
        fechaInicio = this.$date.parseDate(this.dataModified.FECHA_INICIO)
        fechaMin = fechaInicio.add(1, 'minute')
      }
      return fechaMin
    },

    acceptDisable () {
      return (!this.valid)
    }

  },

  watch: {
    show () {
      if (this.show && this.dataNivel) {
        this.dataModified = this.dataNivel
        this.dataModified.FECHA_INICIO = this.$date.formatDate(this.dataNivel.FECHA_INICIO, 'DD/MM/YYYY HH:mm')
        this.dataModified.FECHA_FIN = this.$date.formatDate(this.dataNivel.FECHA_FIN, 'DD/MM/YYYY HH:mm')
      }
    }
  },

  methods: {

    close () {
      this.$emit('close')
    },

    aceptar () {
      let nivelIncendio = this.incendio.NIVELES.find(x => x.ID_INCENDIO_NIVEL === this.dataModified.ID_INCENDIO_NIVEL)

      if (nivelIncendio) {
        let data = {
          ID_INCENDIO_NIVEL: this.dataModified.ID_INCENDIO_NIVEL,
          dataEstado: {
            ID_INCENDIO: this.idIncendio,
            ID_TIPO_INCENDIO_NIVEL: nivelIncendio.ID_TIPO_INCENDIO_NIVEL,

            FECHA_INICIO: this.$date.parseDate(this.dataModified.FECHA_INICIO),
            FECHA_FIN: this.$date.parseDate(this.dataModified.FECHA_FIN)
          }

        }
        this.$store.dispatch('incendio/editNivelIncendio', data)
        this.$emit('redrawIncendio')
        this.$emit('close')
      }
    }

  },
  mounted () {
    this.dataModified = {
      FECHA_INICIO: null,
      FECHA_FIN: null,
      ID_INCENDIO: null,
      ID_INCENDIO_NIVEL: null,
      ID_TIPO_INCENDIO_NIVEL: null,
      TIPO_INCENDIO_NIVEL: {
        INCENDIO_NIVEL: null
      },
      index: null
    }
  },
  created () {
    this.dataModified = {
      FECHA_INICIO: null,
      FECHA_FIN: null,
      ID_INCENDIO: null,
      ID_INCENDIO_NIVEL: null,
      ID_TIPO_INCENDIO_NIVEL: null,
      TIPO_INCENDIO_NIVEL: {
        INCENDIO_NIVEL: null
      },
      index: null
    }
  }
}
</script>

<style scoped>

</style>
