import request from './request'
import auth from './auth'

import actuaciones from './actuaciones'
import imagenSatelite from './imagenSatelite'
import imagenIncendio from './imagenIncendio'
import incendio from './incendio'
import medio from './medio'
import parametro from './parametro'
import planificacion from './planificacion'
import proxy from './proxy'
import simulacion from './simulacion'

import others from './others'

export default {
  request,

  auth,

  actuaciones,
  imagenIncendio,
  imagenSatelite,
  incendio,
  medio,
  parametro,
  planificacion,
  proxy,
  simulacion,

  others
}
