<template>
  <div>
    <v-card elevation="5">
      <v-toolbar color="primaryLight" dark dense style="z-index: 2;">
        <!-- Toolbar para poder enganchar el FAB -->
        <v-tabs v-model="categoriaSelected" dark slider-color="secondary">
          <v-tab v-for="(medio, n) in categoriasMedios" :key="medio.CATEGORIA" ripple>
            <v-icon>{{ medio.ICONO }}</v-icon>
            <label class="caption pb-3 pr-1">{{ numMedios[n] }}</label>
            {{ medio.CATEGORIA }}
          </v-tab>
        </v-tabs>

        <!-- <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right absolute :disabled="isIncendioFinalizado" @click="showDialogAddMedios = true">
              <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Añadir medio</span>
        </v-tooltip> -->
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right absolute :disabled="disabledByRol || isIncendioFinalizado" @click="showDialogEditSectores = true">
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar sectores</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text class="pa-0">
        <vx-medios-table :medios="mediosTable" @editMedioIncendio="editMedioIncendio" @setFecha="setFecha" />
      </v-card-text>
    </v-card>

    <vx-dialog-add-medios :show="showDialogAddMedios" :idIncendio="idIncendio" @aceptar="acceptAddMediosIncendio" @cancelar="cancelAddMediosIncendio" />

    <vx-dialog-edit-medio :show="showDialogEditMedio" :idIncendio="idIncendio" :medioSector="medioEdit" @aceptar="acceptEditMedioIncendio" @cancelar="cancelEditMedioIncendio" @deleteMedioIncendio="deleteMedioIncendio" />
    <vx-change-medio-sector-table :show="showDialogEditSectores" :medios="mediosTable" :idIncendio="idIncendio" @acceptEditSectorIncendio="acceptEditSectorIncendio" @close="closeChangeMedioSector" />
  </div>
</template>

<script>
import constant from '../../../helpers/constants'

import MediosTable from './MediosTable'
import DialogAddMedios from './DialogAddMedios'
import DialogEditMedio from './DialogEditMedio'
import ChangeMedioSectorTable from './ChangeMedioSectorTable'

export default {
  components: {
    'vx-medios-table': MediosTable,
    'vx-dialog-add-medios': DialogAddMedios,
    'vx-dialog-edit-medio': DialogEditMedio,
    'vx-change-medio-sector-table': ChangeMedioSectorTable
  },

  props: {
    sectoresSelected: Array,
    idIncendio: String
  },

  data: () => ({
    numMedios: [],
    categoriasMedios: [],

    categoriaSelected: 0,

    showDialogAddMedios: false,
    showDialogEditMedio: false,
    showDialogEditSectores: false,

    medioEdit: {}
  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    isIncendioFinalizado () {
      return this.$store.getters['incendio/isIncendioFinalizado'](this.idIncendio)
    },

    medios () {
      return this.$store.getters['medio/medios']
    },

    sectores () {
      let sectores = []

      if (this.incendio) {
        sectores = this.incendio.SECTORES
      }

      return sectores
    },

    mediosTable () {
      let medioSector = {}

      this.countNumMedios()

      let categoriaMedio = this.categoriasMedios[this.categoriaSelected].CATEGORIA

      let mediosTable = []
      // Recorro los sectores seleccionados
      for (let i = 0; i < this.sectoresSelected.length; i++) {
        let sectorSelected = this.sectoresSelected[i]

        // Recorro los medios del sector
        for (let j = 0; j < this.sectores[sectorSelected].MEDIOS.length; j++) {
          let medioSectorSelected = this.sectores[sectorSelected].MEDIOS[j]

          for (let k = 0; k < this.medios.length; k++) {
            let medio = this.medios[k]
            if (medioSectorSelected.ID_MEDIO === medio.ID_MEDIO && medioSectorSelected.FECHA_ALARMADO) { // Solo muestra los REAL, no los PLANIF
              if (medio.CATEGORIA === categoriaMedio || this.categoriaSelected === 0) {
                medioSector = {
                  ID_INCENDIO: this.idIncendio,
                  ID_SECTOR: medioSectorSelected.ID_SECTOR,
                  SECTOR: sectorSelected.SECTOR,
                  ID_MEDIO_SECTOR: medioSectorSelected.ID_MEDIO_SECTOR,
                  ID_MEDIO: medio.ID_MEDIO,
                  MEDIO: medio.MEDIO,
                  ESTADO: medio.ESTADO,
                  FECHA_ALARMADO: medioSectorSelected.FECHA_ALARMADO,
                  FECHA_EN_CAMINO: medioSectorSelected.FECHA_EN_CAMINO,
                  FECHA_EN_LUGAR: medioSectorSelected.FECHA_EN_LUGAR,
                  FECHA_FINALIZADO: medioSectorSelected.FECHA_FINALIZADO,
                  FECHA_LLEGADA_GEOFENCING: medioSectorSelected.FECHA_LLEGADA_GEOFENCING,
                  FECHA_FIN_DESCANSO: medioSectorSelected.FECHA_FIN_DESCANSO,
                  NUM_COMPONENTES: medioSectorSelected.NUM_COMPONENTES,
                  PLANIFICACION: medioSectorSelected.PLANIFICACION
                }

                mediosTable.push(medioSector)
              }
            }
          }
        }
      }

      return mediosTable
    },

    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    }
  },

  watch: {
    idIncendio () {
      this.categoriaSelected = 0
    }
  },

  methods: {
    countNumMedios () {
      let todos = 0
      let numRecurso = 0
      let numTerrestre = 0
      let numAereo = 0

      // Recorro los sectores seleccionados
      for (let i = 0; i < this.sectoresSelected.length; i++) {
        let index = this.sectoresSelected[i]
        let sector = this.sectores[index]

        // Recorro los medios del sector
        for (let j = 0; j < sector.MEDIOS.length; j++) {
          let medioSectorSelected = JSON.parse(JSON.stringify(sector.MEDIOS[j]))

          // if (medioSectorSelected.FECHA_ALARMADO && !medioSectorSelected.FECHA_EN_LUGAR) {
          // Busco el medio correspondiente para saber el tipo
          let medio = this.medios.find(x => x.ID_MEDIO === medioSectorSelected.ID_MEDIO && medioSectorSelected.FECHA_ALARMADO)
          if (medio) {
            if (medio.CATEGORIA === 'Recurso') {
              numRecurso = numRecurso + 1
            } else if (medio.CATEGORIA === 'Terrestre') {
              numTerrestre = numTerrestre + 1
            } else {
              numAereo = numAereo + 1
            }
            todos = todos + 1
          }
          // }
        }
      }
      // pongo el numero de medios segun esta en la tabla
      this.numMedios = [todos, numRecurso, numTerrestre, numAereo]
    },

    // Añadir medio
    acceptAddMediosIncendio (medios) {
      this.showDialogAddMedios = false
      this.$store.dispatch('incendio/addMediosIncendio', medios)
    },

    cancelAddMediosIncendio () {
      this.showDialogAddMedios = false
    },

    // Click en boton reloj
    setFecha (medio) {
      this.$store.dispatch('incendio/editMedioIncendio', medio)
    },

    // Editar medio
    editMedioIncendio (medio) {
      this.showDialogEditMedio = true
      this.medioEdit = medio
    },

    acceptEditMedioIncendio (medioEdit) {
      this.showDialogEditMedio = false
      this.$store.dispatch('incendio/editMedioIncendio', medioEdit)
      this.medioEdit = {}
    },

    cancelEditMedioIncendio () {
      this.showDialogEditMedio = false
      this.medioEdit = {}
    },

    // Borrar medio
    deleteMedioIncendio (medioDelete) {
      this.showDialogEditMedio = false
      this.medioEdit = {}
      this.$store.dispatch('incendio/deleteMedioIncendio', medioDelete)
    },

    // Editar sectores
    editSectorIncendio (medio) {
      this.showDialogEditMedioSector = true
      this.medioEdit = medio
    },
    acceptEditSectorIncendio (medioEdit) {
      this.showDialogEditMedioSector = false
      this.$store.dispatch('incendio/editMedioIncendio', medioEdit)
      this.medioEdit = {}
    },
    closeChangeMedioSector () {
      this.showDialogEditSectores = false
      this.medioEdit = {}
    }
  },

  created () {
    this.categoriasMedios = null
    this.categoriasMedios = JSON.parse(JSON.stringify(constant.categoriasMedios))
    this.categoriasMedios.unshift(constant.allMedios)
  }
}
</script>

<style scoped>
</style>
