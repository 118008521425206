<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense style="z-index: 2;">
      <v-toolbar-title>Planificación medios terrestres</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-container fluid class="pa-0">
        <!-- Barra superior -->
        <v-row align="center" no-gutters>
          <v-col>
            <v-select v-model="incendioSelected" :items="incendiosActivos" label="Incendios" :item-text="item => item.NOMBRE ? item.NOMBRE : item.MUNICIPIO" persistent-hint return-object />
          </v-col>

          <v-col>
            <v-text-field v-model="fechaIncendio" label="Fecha incendio" readonly />
          </v-col>

          <v-col sm="1">
            <v-text-field v-model="orto" label="Orto" readonly />
          </v-col>

          <v-col sm="1">
            <v-text-field v-model="ocaso" label="Ocaso" readonly />
          </v-col>

          <v-col>
            <v-text-field v-model="fechaActual" label="Fecha/hora actual" readonly />
          </v-col>

          <v-col sm="3">
            <v-select v-model="filtrosSelected" :items="filtros" label="Filtros" multiple return-object>
              <template #selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption">(+{{ filtrosSelected.length - 1 }} otros)</span>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <!-- Tabs -->
        <v-toolbar color="primaryLight" dense style="z-index: 2">
          <v-tabs v-model="tabSelected" color="white" background-color="primaryLight" dark slider-color="white">
            <v-tab v-for="t in tabs" :key="t" ripple @click="cambiarTab(t)">
              {{ t }}
            </v-tab>
          </v-tabs>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right absolute @click="abrirDialogAddMedio()" :disabled="disabledByRol">
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Añadir medio</span>
          </v-tooltip>
        </v-toolbar>

        <v-tabs-items v-model="tabSelected">
          <v-tab-item key="Tabla" style="height: 60vh; overflow: auto !important;">
            <v-card flat id="tabla">
              <v-card-text>
                <div style="display: inline-flex; align-items: center; width: 100%">
                  <v-btn :disabled="!mediosAsignados || mediosAsignados.length === 0" text icon color="grey" @click="capturaTabla()">
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </div>

                <v-data-table :headers="headers" :items="mediosAsignados" single-expand hide-default-footer disable-pagination>
                  <template #no-data>
                    <p>No hay medios asignados.</p>
                  </template>

                  <template #item="{ item }">
                    <tr v-if="!item.hide">
                      <!-- @click="expand(!isExpanded)" -->
                      <td class="text-xs">{{ item.MEDIO }}</td>
                      <td class="text-xs">{{ item.ACTUACION }}</td>
                      <td class="text-xs">{{ item.TIPO }}</td>
                      <td class="text-xs">{{ item.SECTOR.SECTOR }}</td>
                      <td class="text-xs">{{ item.NUM_COMPONENTES }}</td>
                      <td class="text-xs">{{ fechahora(item.INICIO_JORNADA) }}</td>
                      <td class="text-xs">{{ item.FECHA_INCORPORACION }}</td>
                      <td class="text-xs">{{ hhmm(item.FECHA_ALARMADO) }}</td>
                      <td class="text-xs">{{ hhmm(item.FECHA_EN_CAMINO) }}</td>
                      <td class="text-xs">{{ fechahora(item.FECHA_EN_LUGAR) }}</td>
                      <td class="text-xs">{{ fechahora(item.FECHA_MAX_ACTUACION) }}</td>
                      <td class="text-xs">{{ fechahora(item.FECHA_FINALIZADO) }}</td>
                      <td class="text-xs">{{ fechahora(item.FECHA_FIN_DESCANSO) }}</td>
                      <td class="text-xs-center">
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" :disabled="isAddActuacionDisabled(item)" @click="abrirDialogAddActuacion(item)">
                              <v-icon color="teal">mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Añadir actuación</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon v-bind="attrs" v-on="on" :disabled="!item.ISPLANIFICACION || item.TIENE_MAS_ACTUACIONES || disabledByRol"
                              @click="abrirDialogEdit(item.ACTUACION > 1, item)"
                            >
                              <v-icon color="black">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" :disabled="!item.ISPLANIFICACION || item.TIENE_MAS_ACTUACIONES || disabledByRol" @click="borrarMedioIncendio(item)">
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar</span>
                        </v-tooltip>
                      </td>
                    </tr>

                    <tr v-else />
                  </template>

                  <!-- <template #expanded-item="{ item }">
                    <v-card flat>
                      {{ item.MEDIO }}
                      <v-card-text>
                        <div style="display: inline-flex" v-html="medioExpandedTable" />
                      </v-card-text>
                    </v-card>
                  </template> -->
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="Gráfica" eager>
            <v-card flat>
              <v-card-text>
                <div style="display: inline-flex; align-items: center; width: 100%">
                  <v-btn :disabled="!mediosAsignados || mediosAsignados.length === 0" text icon color="grey" @click="capturaGrafica()">
                    <v-icon>mdi-camera-image</v-icon>
                  </v-btn>
                </div>
                <div id="timeline" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-card-text>

    <vx-dialog-add-medio-planificacion
      :show="showDialogAdd"
      :editedItem="editedItem"
      :isEdit="isEdit"
      :medios="mediosDialog"
      :sectores="sectores"
      :idIncendioSelected="incendioSelected ? incendioSelected.ID_INCENDIO : null"
      @cancelar="showDialogAdd = false"
      @aceptar="addMedioIncendio"
      @editar="editarMedioIncendio"
    />

    <vx-dialog-add-actuacion
      :show="showDialogActuacion"
      :editedItem="editedItem"
      :isEdit="isEdit"
      :sectores="sectores"
      :periodoAnterior="periodoAnterior"
      @cancelar="showDialogActuacion = false"
      @aceptar="addActuacionMedio"
      @editar="editarActuacionMedio"
    />
  </v-card>
</template>

<script>
import { getSunrise, getSunset } from 'sunrise-sunset-js'
import { Timeline, DataSet } from 'vis-timeline/standalone'

import DialogAddMedioPlanificacionMMTT from './DialogAddMedioPlanificacionMMTT'
import DialogAddActuacionMMTT from './DialogAddActuacionMMTT'
import api from '@/api'
import * as PH from '../../helpers/PlanificacionHelper'
import * as htmlToImage from 'html-to-image'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import constants from '@/helpers/constants'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'PlanificacionMMTT',

  props: {
    show: Boolean
  },

  components: {
    'vx-dialog-add-medio-planificacion': DialogAddMedioPlanificacionMMTT,
    'vx-dialog-add-actuacion': DialogAddActuacionMMTT
  },

  data: () => ({
    incendioSelected: null,

    oldIncendioActivo: null,

    tabs: ['Tabla', 'Gráfica'],
    tabSelected: null,

    timeout: null,
    timer: null,

    showDialogAdd: false,
    showDialogActuacion: false,

    isEdit: false,
    editedItem: {},

    firstTimeFit: false,
    fechaActual: null,

    filtros: ['Retén', 'Retén Tragsa', 'Cuadrilla Helitransportada', 'Técnico', 'A. Forestal', 'Autobomba', 'Bulldozer',
      // Nuevos medios
      // Terrestres
      'Vehículo Auto Escalera',
      'Vehículo Rescate Acuatico',
      'Equipo Rescate Montaña',
      'Bomba Urbana Pesada',
      'Vehículo Rescate Rapido',
      'Bomba Forestal Ligera',
      'Remolque Electrobomba',
      'Bomba Urbana Ligera',
      'Bomba Rural Ligera',
      'Vehículo Transporte Organos',
      'Bomba Forestal Pesada',
      'Vehículo Transporte Mando',
      'Vehículo Poli-Brazo/Contenedor',
      'Bomba Forestal',
      'Cuña Quitanieves',
      'Vehículo Servicios',
      'Bomba Rural Pesada',
      'Vehículo Rescate Montaña',
      'Vehículo Auto Brazo',
      'Vehículo Rescate Taller',
      'Vehículo Mercancías Peligrosas',
      'Vehículo Asistencias Tecnicas',
      'Remolque Embarcac. Motor',
      'Vehículo Transporte Personal',
      'Vehículo Transporte Perros Sal',
      'Bomberos',
      'Vehículo Servicios Multiples',
      'Bomba Nodriza Pesada',
      // Recursos
      'Heli Transp. Brigada BRIF',
      'Jefe de Parque',
      'SAMU Rescate',
      'Jefe Perros de Salvamento',
      'Observatorio Forestal',
      'Sargento de Guardia: Aralar'],
    filtrosSelected: ['Retén', 'Retén Tragsa', 'Cuadrilla Helitransportada', 'Técnico', 'A. Forestal', 'Autobomba', 'Bulldozer',
      // Nuevos medios
      // Terrestres
      'Vehículo Auto Escalera',
      'Vehículo Rescate Acuatico',
      'Equipo Rescate Montaña',
      'Bomba Urbana Pesada',
      'Vehículo Rescate Rapido',
      'Bomba Forestal Ligera',
      'Remolque Electrobomba',
      'Bomba Urbana Ligera',
      'Bomba Rural Ligera',
      'Vehículo Transporte Organos',
      'Bomba Forestal Pesada',
      'Vehículo Transporte Mando',
      'Vehículo Poli-Brazo/Contenedor',
      'Bomba Forestal',
      'Cuña Quitanieves',
      'Vehículo Servicios',
      'Bomba Rural Pesada',
      'Vehículo Rescate Montaña',
      'Vehículo Auto Brazo',
      'Vehículo Rescate Taller',
      'Vehículo Mercancías Peligrosas',
      'Vehículo Asistencias Tecnicas',
      'Remolque Embarcac. Motor',
      'Vehículo Transporte Personal',
      'Vehículo Transporte Perros Sal',
      'Bomberos',
      'Vehículo Servicios Multiples',
      'Bomba Nodriza Pesada',
      // Recursos
      'Heli Transp. Brigada BRIF',
      'Jefe de Parque',
      'SAMU Rescate',
      'Jefe Perros de Salvamento',
      'Observatorio Forestal',
      'Sargento de Guardia: Aralar'],

    headers: [
      { text: 'Medio', value: 'MEDIO', align: 'left' },
      { text: 'Actuación', value: 'ACTUACION', align: 'left', sortable: false },
      { text: 'Tipo', value: 'TIPO', align: 'left' },
      { text: 'Sector', value: 'SECTOR.SECTOR', align: 'left' },
      { text: 'Nº componentes', value: 'NUM_COMPONENTES', align: 'left' },
      { text: 'Inicio de jornada', value: 'INICIO_JORNADA', align: 'left' },
      { text: 'Incorporación al incendio', value: 'FECHA_INCORPORACION', align: 'left' },
      { text: 'Movilización a incendio', value: 'FECHA_ALARMADO', align: 'left' },
      { text: 'Llegada a incendio', value: 'FECHA_EN_CAMINO', align: 'left' },
      { text: 'Fecha salida incendio', value: 'FECHA_EN_LUGAR', align: 'left' },
      { text: 'Fecha máxima de actuación', value: 'FECHA_MAX_ACTUACION', align: 'left' },
      { text: 'Llegada base', value: 'FECHA_FINALIZADO', align: 'left' },
      { text: 'Fin descanso', value: 'FECHA_FIN_DESCANSO', align: 'left' },
      { text: 'Acciones', value: 'name', sortable: false, align: 'center', width: '8%' }
    ],

    mediosDialog: [],

    mediosAsignados: [],

    periodoAnterior: {},

    numDiasPasado: 3,
    numDiasFuturo: 3
  }),

  computed: {
    incendiosActivos () {
      let incendios = JSON.parse(JSON.stringify(this.$store.getters['incendio/incendios']))
      incendios.sort((a, b) => {
        let aEstado = a.VALUE_ESTADO
        let bEstado = b.VALUE_ESTADO
        let aFecha = a.FECHA_CREACION
        let bFecha = b.FECHA_CREACION

        if (aEstado === bEstado) {
          return (aFecha > bFecha) ? -1 : (aFecha < bFecha) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })
      return incendios
    },

    sectores () {
      let sectores = []

      if (this.incendioSelected && this.incendioSelected.SECTORES) {
        this.incendioSelected.SECTORES.forEach((s) => {
          sectores.push({ ID_SECTOR: s.ID_SECTOR, SECTOR: s.SECTOR })
        })
        sectores.sort((x, y) => {
          return x.SECTOR === 'GENERAL' ? -1 : y.SECTOR === 'GENERAL' ? 1 : 0
        })
      }

      return sectores
    },

    medios () {
      let medios = this.$store.getters['medio/medios']
      let mediosFiltered = medios.filter(x => x.CATEGORIA !== 'Aéreo').sort((a, b) => {
        return a.MEDIO - b.MEDIO
      })
      // TODO: revisar si esta linea hace falta aqui, o se puede meter en el dialog this.mediosDialog = this.medios.filter(x => x.ESTADO === 0)
      return mediosFiltered
    },

    fechaIncendio () {
      let fecha = null
      if (this.incendioSelected && this.incendioSelected.FECHA_CREACION) {
        fecha = this.$date.formatDate(this.incendioSelected.FECHA_CREACION, 'DD/MM/YYYY HH:mm')
      }

      return fecha
    },

    orto () {
      let orto = null
      if (this.incendioSelected && this.incendioSelected.LATITUD) {
        let sunrise = getSunrise(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, new Date(this.incendioSelected.FECHA_CREACION))
        orto = this.$date.formatDate(sunrise.toISOString(), 'HH:mm')
      }

      return orto
    },

    ocaso () {
      let ocaso = null
      if (this.incendioSelected && this.incendioSelected.LATITUD) {
        let sunset = getSunset(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, new Date(this.incendioSelected.FECHA_CREACION))
        ocaso = this.$date.formatDate(sunset.toISOString(), 'HH:mm')
      }

      return ocaso
    },
    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    }
  },

  watch: { // TODO:
    incendiosActivos (val) {
      let is = this.incendioSelected ? this.incendiosActivos.find(x => x.ID_INCENDIO === this.incendioSelected.ID_INCENDIO) : null
      // console.log('Han cambiado los incendios', this.incendioSelected, is, this.incendioSelected === is)
      if (this.incendiosActivos.length > 0 && !this.incendioSelected) {
        this.incendioSelected = this.incendiosActivos[0]
        this.oldIncendioActivo = this.incendiosActivos
      }

      if (JSON.stringify(val) === JSON.stringify(this.oldIncendioActivo)) return

      if (this.incendioSelected) { // HACK: el refresher modifica los incendios y luego el watch de 'incendioSelected.SECTORES' no funciona
        is = this.incendiosActivos.find(x => x.ID_INCENDIO === this.incendioSelected.ID_INCENDIO)
        this.incendioSelected = is
        this.oldIncendioActivo = this.incendiosActivos
      }
    },

    medios (val, oldVal) { // Necesario para carga inicial al refrescar la web en esta ruta
      if (oldVal.length === 0) {
        this.mediosAsignadosIncendio()
        this.actualizarDatos()
      }
    },

    incendioSelected () {
      if (!this.timeline) {
        this.initTimeline()
      }

      /* if (this.incendioSelected) {
        this.actualizarDatos()
      } */
    },

    'incendioSelected.SECTORES': { // Este watch controla cuando se añaden y/o borran medios
      handler () {
        // if (JSON.stringify(this.incendioSelected.SECTORES[0].MEDIOS) === JSON.stringify(val)) return

        this.mediosAsignadosIncendio()
        this.actualizarDatos()
      },
      deep: true
    },

    filtrosSelected () {
      this.filtrarDatos()
    }
  },

  methods: {
    async init () {
      this.fechaActual = this.$date.currentDate()
      // this.getListaMedios()

      // this.incendiosActivos = this.orderIncendios(this.$store.getters.getChangeIncendios)

      // Seleccionar primer incendio
      if (this.incendiosActivos.length > 0 && !this.incendioSelected) {
        this.incendioSelected = this.incendiosActivos[0]
      }

      // this.initTimeline()

      // El timeout hace que salte exactamente en el segundo 00
      let secondsLeft = 60 - new Date().getSeconds()
      this.timeout = setTimeout(() => {
        this.fechaActual = this.$date.currentDate()
        // this.actualizarDatos()

        // No se para que sirve
        this.timer = setInterval(() => {
          this.fechaActual = this.$date.currentDate()
          if (this.timeline) {
            this.mediosAsignadosIncendio()
            this.actualizarDatos()
          }
        }, 1 * 10000)
      }, secondsLeft * 1000)
    },

    capturaTabla () { // TODO:
      let bodyPdf = []
      bodyPdf.push(['Medio', 'Actuación', 'Tipo', 'Sector', 'Nº comp.', 'Inicio de jornada', 'Incorp. incendio', 'Movil. incendio', 'Llegada a incendio', 'Fecha máxima de actuación', 'Llegada base', 'Fin descanso'])
      for (let i = 0; i < this.mediosAsignados.length; i++) {
        let medio = this.mediosAsignados[i]

        let datosMedio = []
        datosMedio.push({ text: medio.MEDIO, bold: true })
        datosMedio.push(medio.ACTUACION)
        datosMedio.push(medio.TIPO)
        datosMedio.push(medio.SECTOR.SECTOR)
        datosMedio.push(medio.NUM_COMPONENTES)
        datosMedio.push(this.$date.parseDate(medio.INICIO_JORNADA).format('DD/MM/YYYY HH:mm'))
        datosMedio.push(medio.FECHA_INCORPORACION)
        datosMedio.push(this.$date.parseDate(medio.FECHA_ALARMADO).format('HH:mm'))
        datosMedio.push(this.$date.parseDate(medio.FECHA_EN_CAMINO).format('HH:mm'))
        datosMedio.push(this.$date.parseDate(medio.FECHA_MAX_ACTUACION).format('DD/MM/YYYY HH:mm'))
        datosMedio.push(this.$date.parseDate(medio.FECHA_FINALIZADO).format('DD/MM/YYYY HH:mm'))
        datosMedio.push(this.$date.parseDate(medio.FECHA_FIN_DESCANSO).format('DD/MM/YYYY HH:mm'))
        bodyPdf.push(datosMedio)
      }

      let docDefinition = {
        pageOrientation: 'landscape',
        content: [

        ],
        defaultStyle: {
          fontSize: 9
        }
      }
      docDefinition.content.push(constants.headerPDF)
      docDefinition.content.push(

        {
          text: [{ text: 'Incendio: ', bold: true }, this.incendioSelected.MUNICIPIO, { text: '  |  Fecha/hora inicio: ', bold: true }, this.$date.parseDate(this.incendioSelected.FECHA_CREACION).format('DD/MM/YYYY HH:mm'),
            { text: '  |  Orto: ', bold: true }, this.orto, { text: '  |  Ocaso: ', bold: true }, this.ocaso,
            { text: '  |  Fecha/hora informe: ', bold: true }, this.fechaActual],
          margin: [0, 0, 0, 15]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            headerRows: 1,
            // widths: [ 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto' ],
            body: bodyPdf
          },
          margin: [0, 15, 0, 20]
        }
      )
      pdfMake.createPdf(docDefinition).download('MMTT_Tiempos_' + this.incendioSelected.MUNICIPIO + '_' + this.$date.now().format('YYYY_MM_DD_hh_mm'))
    },

    async capturaGrafica () { // TODO:
      try {
        let dataUrl = await htmlToImage.toJpeg(document.getElementById('timeline'), { quality: 0.95 })

        let link = document.createElement('a')
        link.download = 'MMTT_' + this.incendioSelected.MUNICIPIO + '_' + this.$date.now().format('YYYY_MM_DD_hh_mm') + '.jpeg'
        link.href = dataUrl
        link.click()
      } catch (err) {
        this.$log.error(err)
      }
    },

    isAddActuacionDisabled (medio) {
      return !medio.FECHA_FINALIZADO || medio.FECHA_FINALIZADO >= this.$date.now() || medio.TIENE_MAS_ACTUACIONES || medio.MAX_ACTUACIONES
    },

    cambiarTab (tab) {
      if (tab === 'Gráfica') {
        setTimeout(() => {
          this.mediosAsignadosIncendio()
          this.actualizarDatos()
          this.timeline.redraw()
          // this.fitTimeline()
        }, 800)
      }
    },

    actualizarDatos () { // Actualiza los datos que se muestran en la timeline objetivo
      // console.log('--------> ACTUALIZAR_DATOS')

      // this.mediosAsignados = []
      // this.mediosAsignadosIncendio()

      // this.mediosDialog = this.medios.filter(x => !self.mediosAsignados.find(y => y.id === x.ID_MEDIO))
      this.mediosDialog = this.medios.filter(x => x.ESTADO === 19)

      this.updateTimeLine()
      this.filtrarDatos()
    },

    mediosAsignadosIncendio () { // Lista los medios asignados al incendio
      this.mediosAsignados = []

      let mediosIncendio = this.getMediosIncendio()

      for (let i = 0; i < mediosIncendio.length; i++) {
        let p = mediosIncendio[i]

        let s = p.sector
        let m = p.medio

        let medio = this.medios.find(x => x.ID_MEDIO === m.ID_MEDIO)

        if (medio) {
          let fechaAviso = m.FECHA_ALARMADO ? m.FECHA_ALARMADO : null
          let llegadaInc = m.FECHA_EN_CAMINO ? m.FECHA_EN_CAMINO : null
          let salidaInc = m.FECHA_EN_LUGAR ? m.FECHA_EN_LUGAR : null
          let llegadaBase = m.FECHA_FINALIZADO ? m.FECHA_FINALIZADO : null
          let finDescanso = m.FECHA_FIN_DESCANSO ? m.FECHA_FIN_DESCANSO : null

          if (/* !llegadaBase &&  */ fechaAviso /* && llegadaInc */) {
            let data = {
              ID_MEDIO: medio.ID_MEDIO,
              ID_MEDIO_SECTOR: m.ID_MEDIO_SECTOR,
              MEDIO: medio.MEDIO,
              TIPO: medio.TIPO,
              SECTOR: s,
              NUM_COMPONENTES: m.NUM_COMPONENTES,
              HORA_ENTRADA: medio.HORA_ENTRADA,
              FECHA_ALARMADO: fechaAviso,
              LLEGADA_INC: llegadaInc,
              SALIDA_INC: salidaInc,
              LLEGADA_BASE: llegadaBase,
              FIN_DESCANSO: finDescanso,
              PLANIFICACION: m.PLANIFICACION,
              ISPLANIFICACION: false
            }
            this.calcularActuacion(data)
          } else {
            let data = {
              ID_MEDIO: medio.ID_MEDIO,
              ID_MEDIO_SECTOR: m.ID_MEDIO_SECTOR,
              MEDIO: medio.MEDIO,
              TIPO: medio.TIPO,
              SECTOR: s,
              NUM_COMPONENTES: m.NUM_COMPONENTES,
              HORA_ENTRADA: medio.HORA_ENTRADA,
              FECHA_ALARMADO: m.FECHA_AVISO_PLANIF,
              LLEGADA_INC: llegadaInc, // m.FECHA_LLEGADA_PLANIF,
              SALIDA_INC: m.FECHA_LLEGADA_PLANIF, // salidaInc,
              LLEGADA_BASE: llegadaBase,
              FIN_DESCANSO: finDescanso,
              PLANIFICACION: m.PLANIFICACION,
              ISPLANIFICACION: true
            }
            this.calcularActuacion(data)
          }
        }
      }
    },

    calcularActuacion (medio) { // Calcula los tiempos de actuacion del medio usando los datos disponibles
      let horaAlarmado = this.$date.parseDate(medio.FECHA_ALARMADO) // ALARMADO
      let actuacion = this.getNumeroActuaciones(medio.ID_MEDIO)

      let planificacion = medio.PLANIFICACION
      let inicioJornada = this.$date.parseDate(medio.FECHA_ALARMADO) // planificacion ? this.$date.parseDate(planificacion.INICIO_JORNADA) : this.$date.parseDate(medio.FECHA_ALARMADO)

      let horaEnCamino = medio.LLEGADA_INC ? this.$date.parseDate(medio.LLEGADA_INC) : null// this.$date.now() // EN CAMINO

      // let inicioJornada = moment(horaEnCamino.format('YYYY-MM-DD') + ' ' + medio.horaEntrada) // Fecha de la llegada al incendio, hora entrada del medio de BBDD

      /* if (inicioJornada > horaEnCamino) {
          inicioJornada.subtract(1, 'days')
        } */
      let fechaInicioJornada = inicioJornada.format('YYYY-MM-DD')
      let horaInicioJornada = inicioJornada.format('HH:mm')

      // Fecha maxima actuacion (salida incendio)
      let tiempoOperativo = planificacion ? planificacion.TIEMPO_OPERATIVO : parseInt(this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_ACTUACION'))

      // let fechaMaximaActuacion = moment(horaAlarmado).add(tiempoOperativo, 'hours')
      let fechaMaximaActuacion = this.$date.parseDate(inicioJornada).add(tiempoOperativo, 'hours')

      // Tener en cuenta el itinere
      // let itinere = horaEnCamino ? horaEnCamino.diff(horaAlarmado, 'minutes') : 0
      let fechaEnLugar = medio.SALIDA_INC ? this.$date.parseDate(medio.SALIDA_INC) : null// this.$date.now() // EN LUGAR

      if (fechaEnLugar && horaEnCamino && fechaEnLugar < horaEnCamino) {
        fechaEnLugar = horaEnCamino
      }

      // fechaMaximaActuacion = fechaMaximaActuacion.subtract(itinere * 2, 'minutes')
      let fechaFinalizado = this.$date.parseDate(fechaMaximaActuacion) // FINALIZADO

      // Sustituir por los datos reales
      if (medio.SALIDA_INC) {
        // fechaMaximaActuacion = moment(medio.SALIDA_INC)
        fechaEnLugar = this.$date.parseDate(medio.SALIDA_INC)
        // fechaFinalizado = horaEnCamino ? this.$date.parseDate(fechaEnLugar).add(horaEnCamino.diff(horaAlarmado, 'minutes'), 'minutes') : fechaMaximaActuacion
      }

      // Fecha llegada base
      // let fechaFinalizado = moment(fechaMaximaActuacion).add(horaEnCamino.diff(horaAlarmado, 'minutes'), 'minutes')
      if (medio.LLEGADA_BASE) {
        fechaFinalizado = this.$date.parseDate(medio.LLEGADA_BASE)
      }

      let tiempoDescanso = planificacion ? planificacion.TIEMPO_DESCANSO : parseInt(this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_DESCANSO'))

      // Fecha fin descanso (siguiente reincorporacion)
      let fechaFinDescanso = medio.FIN_DESCANSO ? this.$date.parseDate(medio.FIN_DESCANSO) : this.$date.parseDate(fechaFinalizado).add(tiempoDescanso, 'hours')

      // Desactivar botones si tiene mas actuaciones
      if (actuacion > 0) {
        for (let i = 0; i < this.mediosAsignados.length; i++) {
          let m = this.mediosAsignados[i]
          if (m.ID_MEDIO === medio.ID_MEDIO && m.ACTUACION === actuacion) {
            m.TIENE_MAS_ACTUACIONES = true
          }
        }
      }
      let data = {
        ID_MEDIO: medio.ID_MEDIO, // Para desplegar la tabla requiere tener un campo 'id'
        MEDIO: medio.MEDIO,
        ID_MEDIO_SECTOR: medio.ID_MEDIO_SECTOR,
        ACTUACION: actuacion + 1,
        TIPO: medio.TIPO,
        SECTOR: medio.SECTOR,
        NUM_COMPONENTES: medio.NUM_COMPONENTES,
        INICIO_JORNADA: fechaInicioJornada + ' ' + horaInicioJornada,
        TIEMPO_OPERATIVO: tiempoOperativo, // planificacion.TIEMPO_OPERATIVO,
        TIEMPO_DESCANSO: tiempoDescanso, // planificacion.TIEMPO_DESCANSO,
        FECHA_INCORPORACION: fechaEnLugar ? fechaEnLugar.format('DD/MM/YYYY') : null,
        FECHA_ALARMADO: horaAlarmado,
        FECHA_EN_CAMINO: horaEnCamino, // EN CAMINO
        FECHA_EN_LUGAR: fechaEnLugar, // EN LUGAR
        FECHA_MAX_ACTUACION: fechaMaximaActuacion,
        FECHA_FINALIZADO: fechaFinalizado, // FECHA LLEGADA BASE
        FECHA_FIN_DESCANSO: fechaFinDescanso,
        ISPLANIFICACION: medio.ISPLANIFICACION
      }

      this.mediosAsignados.push(data)
    },

    filtrarDatos () { // Filtra los grupos de planificacion
      let ids = []
      if (this.filtrosSelected.includes('Retén')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Retén').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Retén Tragsa')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Retén Tragsa').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Cuadrilla Helitransportada')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Cuadrilla Helitransportada').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('A. Forestal')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Agente Forestal').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Técnico')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO.includes('Técnico')).map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Autobomba')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Autobomba').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Bulldozer')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Bulldozer').map(t => t.ID_MEDIO))
      }

      // Nuevos tipos de medio
      // Terrestres
      if (this.filtrosSelected.includes('Vehículo Auto Escalera')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Auto Escalera').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Rescate Acuatico')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Rescate Acuatico').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Equipo Rescate Montaña')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Equipo Rescate Montaña').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Bomba Urbana Pesada')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Bomba Urbana Pesada').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Rescate Rapido')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Rescate Rapido').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Bomba Forestal Ligera')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Bomba Forestal Ligera').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Remolque Electrobomba')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Remolque Electrobomba').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Bomba Urbana Ligera')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Bomba Urbana Ligera').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Bomba Rural Ligera')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Bomba Rural Ligera').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Transporte Organos')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Transporte Organos').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Bomba Forestal Pesada')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Bomba Forestal Pesada').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Transporte Mando')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Transporte Mando').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Poli-Brazo/Contenedor')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Poli-Brazo/Contenedor').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Bomba Forestal')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Bomba Forestal').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Cuña Quitanieves')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Cuña Quitanieves').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Servicios')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Servicios').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Bomba Rural Pesada')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Bomba Rural Pesada').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Rescate Montaña')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Rescate Montaña').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Auto Brazo')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Auto Brazo').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Rescate Taller')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Rescate Taller').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Mercancías Peligrosas')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Mercancías Peligrosas').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Asistencias Tecnicas')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Asistencias Tecnicas').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Remolque Embarcac. Motor')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Remolque Embarcac. Motor').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Transporte Personal')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Transporte Personal').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Transporte Perros Sal')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Transporte Perros Sal').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Bomberos')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Bomberos').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Vehículo Servicios Multiples')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Vehículo Servicios Multiples').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Bomba Nodriza Pesada')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Bomba Nodriza Pesada').map(t => t.ID_MEDIO))
      }

      // Recursos
      if (this.filtrosSelected.includes('Heli Transp. Brigada BRIF')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Heli Transp. Brigada BRIF').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Jefe de Parque')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Jefe de Parque').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('SAMU Rescate')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'SAMU Rescate').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Jefe Perros de Salvamento')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Jefe Perros de Salvamento').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Observatorio Forestal')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Observatorio Forestal').map(t => t.ID_MEDIO))
      }
      if (this.filtrosSelected.includes('Sargento de Guardia: Aralar')) {
        ids = ids.concat(this.mediosAsignados.filter(t => t.TIPO === 'Sargento de Guardia: Aralar').map(t => t.ID_MEDIO))
      }

      this.mediosAsignados.forEach((m) => {
        if (ids.includes(m.ID_MEDIO)) {
          m.hide = false
        } else m.hide = true
      })

      // Ocultar los grupos que no se deben ver: vacios, que no cumplen el filtro
      if (this.timeline && this.timeline.groupsData) {
        this.timeline.groupsData._data.forEach((group) => {
          if (ids.includes(group.id)) {
            this.timeline.groupsData._data.update({ id: group.id, visible: true })
          } else this.timeline.groupsData._data.update({ id: group.id, visible: false })
        })
      }
    },

    /* getListaMedios () { // Obtiene la lista de medios
      this.medios = this.$store.getters.getMedios.filter(x => x.CATEGORIA !== 'Aéreo').sort((a, b) => {
        return a.MEDIO - b.MEDIO
      })
      this.mediosDialog = this.medios.filter(x => x.ESTADO === 0)
    }, */

    getMediosIncendio () { // Devuelve la lista de los medios en cada sector
      let listaMediosIncendio = []

      if (this.incendioSelected) {
        for (let i = 0; i < this.incendioSelected.SECTORES.length; i++) {
          let s = this.incendioSelected.SECTORES[i]

          for (let j = 0; j < s.MEDIOS.length; j++) {
            let m = s.MEDIOS[j]

            listaMediosIncendio.push({ // TODO: mayuscula // TODO: en sector no está la lista de medios???
              sector: JSON.parse(JSON.stringify(s)),
              medio: JSON.parse(JSON.stringify(m))
            })
          }
        }

        listaMediosIncendio = listaMediosIncendio.sort((a, b) => { // Ordenar por fecha aviso para numerar actuaciones
          let fechaA = a.medio.FECHA_ALARMADO ? this.$date.parseDate(a.medio.FECHA_ALARMADO).valueOf() : this.$date.parseDate(a.medio.FECHA_AVISO_PLANIF).valueOf()
          let fechaB = b.medio.FECHA_ALARMADO ? this.$date.parseDate(b.medio.FECHA_ALARMADO).valueOf() : this.$date.parseDate(b.medio.FECHA_AVISO_PLANIF).valueOf()
          return fechaA - fechaB
        })
      }

      return listaMediosIncendio
    },

    getNumeroActuaciones (idMedio) { // Devuelve el numero de actuaciones de un medio concreto
      return this.mediosAsignados.filter(x => x.ID_MEDIO === idMedio).length
    },

    // #region Dialogs Add/Edit Medio y Actuacion
    abrirDialogAddMedio () { // Muestra el panel de añadir un medio a la timeline
      this.showDialogAdd = true

      this.isEdit = false
      this.editedItem = {}
    },

    async addMedioIncendio (data) { // Añade el medio a la lista y al incendio
      this.showDialogAdd = false

      try {
        // Actualizar inicio de jornada
        let medio = JSON.parse(JSON.stringify(this.medios.find(x => x.ID_MEDIO === data.ID_MEDIO)))
        // medio.ESTADO = 7
        medio.HORA_ENTRADA = this.$date.parseDate(data.INICIO_JORNADA).format('HH:mm')
        await this.$store.dispatch('medio/updateMedio', medio)

        // Add medio al incendio
        let mediosIncendio = {
          ID_INCENDIO: this.incendioSelected.ID_INCENDIO,
          ID_SECTOR: data.SECTOR.ID_SECTOR,
          MEDIOS: []
        }

        let idMedioSector = this.$uuid.createUUID()
        let planificacion = {
          ID_PLANIFICACION_MEDIO: this.$uuid.createUUID(),
          ID_MEDIO_SECTOR: idMedioSector,
          INICIO_JORNADA: data.INICIO_JORNADA,
          TIEMPO_OPERATIVO: data.TIEMPO_OPERATIVO,
          TIEMPO_DESCANSO: data.TIEMPO_DESCANSO
        }

        let medioSector = {
          ID_MEDIO_SECTOR: idMedioSector,
          ID_SECTOR: data.SECTOR.ID_SECTOR,
          ID_MEDIO: data.ID_MEDIO,
          FECHA_ALARMADO: data.FECHA_ALARMADO,
          FECHA_EN_LUGAR: data.FECHA_EN_CAMINO,
          FECHA_AVISO_PLANIF: data.FECHA_ALARMADO,
          FECHA_LLEGADA_PLANIF: data.FECHA_EN_CAMINO,
          NUM_COMPONENTES: data.NUM_COMPONENTES,
          PLANIFICACION: planificacion
        }

        // Comprobar si es a futuro o real
        // let now = this.$date.now()
        // if (data.FECHA_ALARMADO > now) {
        medioSector.FECHA_ALARMADO = null
        medioSector.FECHA_EN_LUGAR = null
        // }

        mediosIncendio.MEDIOS.push(medioSector)
        this.$store.dispatch('incendio/addMediosIncendio', mediosIncendio)
      } catch (err) {
        this.$log.error(err)
      }
    },

    async editarMedioIncendio (data) { // Edita un medio
      this.showDialogAdd = false

      for (let i = 0; i < this.mediosAsignados.length; i++) {
        if (this.mediosAsignados[i].ID_MEDIO === data.ID_MEDIO && this.mediosAsignados[i].ACTUACION === data.ACTUACION) {
          try {
            // Actualizar inicio de jornada
            let medio = this.medios.find(x => x.ID_MEDIO === data.ID_MEDIO)
            medio.HORA_ENTRADA = this.$date.parseDate(data.INICIO_JORNADA).format('HH:mm')
            await this.$store.dispatch('medio/updateMedio', medio)
          } catch (err) {
            this.$log.error(err)
          }

          let oldSector = this.mediosAsignados[i].SECTOR.ID_SECTOR
          let prevMedio = this.incendioSelected.SECTORES.find(x => x.ID_SECTOR === oldSector).MEDIOS.find(x => x.ID_MEDIO === data.ID_MEDIO)

          prevMedio.FECHA_ALARMADO = data.FECHA_ALARMADO
          prevMedio.FECHA_EN_CAMINO = data.FECHA_EN_CAMINO
          prevMedio.FECHA_AVISO_PLANIF = data.FECHA_ALARMADO
          prevMedio.FECHA_LLEGADA_PLANIF = data.FECHA_EN_CAMINO
          prevMedio.NUM_COMPONENTES = data.NUM_COMPONENTES
          prevMedio.ID_SECTOR = data.SECTOR.ID_SECTOR
          prevMedio.ID_INCENDIO = this.incendioSelected.ID_INCENDIO

          // Comprobar si es a futuro o real
          // let now = this.$date.now()
          // if (data.FECHA_ALARMADO > now) {
          prevMedio.FECHA_ALARMADO = null
          prevMedio.FECHA_EN_CAMINO = null
          // }

          this.$store.dispatch('incendio/editMedioIncendio', prevMedio)

          let newPlanif = prevMedio.PLANIFICACION
          newPlanif.INICIO_JORNADA = data.INICIO_JORNADA
          newPlanif.TIEMPO_OPERATIVO = data.TIEMPO_OPERATIVO
          newPlanif.TIEMPO_DESCANSO = data.TIEMPO_DESCANSO
          let planificacion = {
            ID_INCENDIO: this.incendioSelected.ID_INCENDIO,
            PLANIFICACION: newPlanif
          }
          this.$store.dispatch('incendio/editPlanificacionMedio', planificacion)
        }
      }
    },

    abrirDialogAddActuacion (data) {
      this.periodoAnterior = data
      this.showDialogActuacion = true

      this.isEdit = false
      this.editedItem = {}
    },

    abrirDialogEdit (isActuacion, item) { // Muestra el panel de edicion de medio / actuacion
      this.isEdit = true
      this.editedItem = Object.assign({}, item)

      if (isActuacion) {
        this.showDialogActuacion = true
        this.periodoAnterior = item
      } else {
        this.showDialogAdd = true
      }
    },

    addActuacionMedio (data) { // Añade una actuacion al medio
      this.showDialogActuacion = false

      // Desactivar + si es la 3act
      if (data.ACTUACION === 3) {
        data.MAX_ACTUACIONES = true
      }

      // Add medio al incendio
      let mediosIncendio = {
        ID_INCENDIO: this.incendioSelected.ID_INCENDIO,
        ID_SECTOR: data.SECTOR.ID_SECTOR,
        MEDIOS: []
      }

      let planificacion = {
        ID_PLANIFICACION_MEDIO: this.$uuid.createUUID(),
        ID_MEDIO_SECTOR: data.ID_MEDIO_SECTOR,
        INICIO_JORNADA: data.INICIO_JORNADA,
        TIEMPO_OPERATIVO: data.TIEMPO_OPERATIVO,
        TIEMPO_DESCANSO: data.TIEMPO_DESCANSO
      }

      let medioSector = {
        ID_MEDIO_SECTOR: data.ID_MEDIO_SECTOR,
        ID_SECTOR: data.SECTOR.ID_SECTOR,
        ID_MEDIO: data.ID_MEDIO,
        FECHA_ALARMADO: data.FECHA_ALARMADO,
        FECHA_EN_CAMINO: data.FECHA_EN_CAMINO,
        FECHA_AVISO_PLANIF: data.FECHA_ALARMADO,
        FECHA_LLEGADA_PLANIF: data.FECHA_EN_CAMINO,
        NUM_COMPONENTES: data.NUM_COMPONENTES,
        PLANIFICACION: planificacion
      }

      // Comprobar si es a futuro o real
      // let now = this.$date.now()
      // if (data.FECHA_ALARMADO > now) {
      medioSector.FECHA_ALARMADO = null
      medioSector.FECHA_EN_CAMINO = null
      // }

      mediosIncendio.MEDIOS.push(medioSector)
      this.$store.dispatch('incendio/addMediosIncendio', mediosIncendio)
    },

    editarActuacionMedio (data) { // Edita una actuacion de un medio
      this.showDialogActuacion = false

      for (let i = 0; i < this.mediosAsignados.length; i++) {
        let medio = this.mediosAsignados[i]

        if (medio.ID_MEDIO === data.ID_MEDIO && medio.ACTUACION === data.ACTUACION) {
          let oldSector = this.mediosAsignados[i].SECTOR.ID_SECTOR

          this.mediosAsignados[i].FECHA_INCORPORACION = data.FECHA_INCORPORACION
          this.mediosAsignados[i].FECHA_ALARMADO = data.FECHA_ALARMADO
          this.mediosAsignados[i].FECHA_EN_CAMINO = data.FECHA_EN_CAMINO
          this.mediosAsignados[i].NUM_COMPONENTES = data.NUM_COMPONENTES
          this.mediosAsignados[i].SECTOR.ID_SECTOR = data.SECTOR.ID_SECTOR

          let prevMedio = this.incendioSelected.SECTORES.find(x => x.ID_SECTOR === oldSector).MEDIOS.find(x => x.ID_MEDIO_SECTOR === data.ID_MEDIO_SECTOR)
          prevMedio.FECHA_ALARMADO = data.FECHA_ALARMADO
          prevMedio.FECHA_EN_CAMINO = data.FECHA_EN_CAMINO
          prevMedio.FECHA_AVISO_PLANIF = data.FECHA_ALARMADO
          prevMedio.FECHA_LLEGADA_PLANIF = data.FECHA_EN_CAMINO
          prevMedio.NUM_COMPONENTES = data.NUM_COMPONENTES
          prevMedio.ID_SECTOR = data.SECTOR.ID_SECTOR
          prevMedio.ID_INCENDIO = this.incendioSelected.ID_INCENDIO

          // Comprobar si es a futuro o real
          // let now = this.$date.now()
          // if (data.FECHA_ALARMADO > now) {
          prevMedio.FECHA_ALARMADO = null
          prevMedio.FECHA_EN_CAMINO = null
          // }

          this.$store.dispatch('incendio/editMedioIncendio', prevMedio)

          let newPlanif = prevMedio.PLANIFICACION
          newPlanif.INICIO_JORNADA = data.INICIO_JORNADA
          newPlanif.TIEMPO_OPERATIVO = data.TIEMPO_OPERATIVO
          newPlanif.TIEMPO_DESCANSO = data.TIEMPO_DESCANSO
          let planificacion = {
            ID_INCENDIO: this.incendioSelected.ID_INCENDIO,
            PLANIFICACION: newPlanif
          }
          this.$store.dispatch('incendio/editPlanificacionMedio', planificacion)
        }
      }
    },

    borrarMedioIncendio (data) { // Elimina el medio
      this.$root.$confirmDialog.open('¿Está seguro?', 'Eliminando actuación').then(result => {
        if (result) {
          /* for (let i = 0; i < self.mediosAsignados.length; i++) {
              let medio = self.mediosAsignados[i]
              if (medio.id === data.id && medio.ACTUACION === data.ACTUACION) {
                self.mediosAsignados.splice(i, 1)

                if (data.ACTUACION > 1) {
                  self.mediosAsignados.find(x => x.id === data.id && x.ACTUACION === data.ACTUACION - 1).TIENE_MAS_ACTUACIONES = false
                }
              }
              self.updateTimeLine()
            } */
          // let medios = self.getMediosIncendio()
          // let idMedioSector = ''
          let idMedioSector = data.ID_MEDIO_SECTOR
          // console.log(data)

          this.medios.find(x => x.ID_MEDIO === data.ID_MEDIO).ESTADO = 24

          this.mediosDialog = this.medios.filter(x => x.ESTADO === 24)

          /* medios.forEach(function (m) {
              if (m.medio.ID_MEDIO === data.id && m.sector.ID_SECTOR === data.SECTOR.ID_SECTOR && moment(m.medio.FECHA_ALARMADO).isSame(data.FECHA_ALARMADO)) {
                idMedioSector = m.medio.ID_MEDIO_SECTOR
              }
            }) */

          let medioDelete = {
            ID_INCENDIO: this.incendioSelected.ID_INCENDIO,
            ID_MEDIO: data.ID_MEDIO,
            ID_SECTOR: data.SECTOR.ID_SECTOR,
            ID_MEDIO_SECTOR: idMedioSector
          }
          this.$store.dispatch('incendio/deleteMedioIncendio', medioDelete)
        }
      })
    },
    // #endregion

    initTimeline () { // Inicializa la timeline
      if (!this.timeline) {
        let container = document.getElementById('timeline')
        this.timeline = new Timeline(container)
        this.timeline.setOptions(this.optionsTimeLine())
      }
    },

    updateTimeLine () { // Actualiza la timeline
      if (this.timeline) {
        // this.timeline.setOptions(this.optionsTimeLine())
        this.setGroups(this.timeline)
        this.setItems(this.timeline)
      }
    },

    setGroups (timeline) { // Crear los grupos de la timeline y los asigna
      // var self = this
      let groups = []

      for (let i = 0; i < this.mediosAsignados.length; i++) {
        let t = this.mediosAsignados[i]

        // Crear cada grupo (medio)
        let initTime = null // Tiempo inicial, para ordenar
        if (t.FECHA_INCORPORACION) {
          initTime = this.$date.parseDate(t.FECHA_ALARMADO)
        }

        // Controlar si ya esta asignado a otro incendio
        if (!groups.find(x => x.id === t.ID_MEDIO)) {
          groups.push({
            id: t.ID_MEDIO,
            content: '<span align="left">' + t.MEDIO + '</span>',
            initTime: initTime,
            className: 'medio'
          })
        }
      }

      let dataSetGroups = new DataSet(groups)
      timeline.setGroups(dataSetGroups)
    },

    setItems (timeline) { // Crea los items de fondo (dia-noche) y los añade a la timeline junto con los items de los medios
      if (this.incendioSelected) {
      // Get lista de items
        let items = this.getItems()

        // Items dia/noche
        let dia = this.$date.now().subtract(this.$date.now().diff(this.incendioSelected.FECHA_CREACION), 'miliseconds')
        let diasTotales = this.$date.now().diff(this.incendioSelected.FECHA_CREACION, 'days') + this.numDiasFuturo + 2
        for (let i = 0; i < diasTotales; i++) {
          let prevSunset = this.$date.parseDate(getSunset(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, dia.toDate()))
          dia = dia.add(1, 'days')
          let sunrise = this.$date.parseDate(getSunrise(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, dia.toDate()))
          let sunset = this.$date.parseDate(getSunset(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, dia.toDate()))

          // Item background noche (antes)
          let item = {
            id: 'noche' + i,
            content: 'Noche',
            start: prevSunset,
            end: sunrise,
            type: 'background',
            className: 'background-noche'
          }
          items.push(item)

          // Item background dia
          item = {
            id: 'dia' + i,
            content: 'Día',
            start: sunrise,
            end: sunset,
            type: 'background',
            className: 'background-dia'
          }
          items.push(item)
        }

        items = PH.convertDatesToUNIX(items)

        let dataSetItems = new DataSet(items)
        timeline.setItems(dataSetItems)

        // Fit timeline
        if (!this.firstTimeFit) { // Solo lo hace la primera vez
          this.firstTimeFit = true
          this.fitTimeline()
        }
      }
    },

    getItems () { // Crear los items de los medios de la timeline
      let items = []
      let tiempo
      let item

      for (let i = 0; i < this.mediosAsignados.length; i++) {
        let t = this.mediosAsignados[i]

        // TODO: Pinta toda la barra, bug. (Mirar larioja/extrem)
        // Pinta un fondo verde porque la linea de INICIO DE JORNADA no llega hasta arriba y abajo (no muestra el title)
        // let item = {
        //   id: this.$uuid.createUUID(),
        //   value: t.MEDIO,
        //   group: t.ID_MEDIO,
        //   content: '  ',
        //   type: 'background',
        //   start: t.INICIO_JORNADA,
        //   end: t.INICIO_JORNADA,
        //   className: 'inicioJornadaLinea',
        //   title: 'Inicio jornada'
        // }
        // items.push(item)

        // // Linea de inicio de jornada (muestra el title)
        // item = {
        //   id: this.$uuid.createUUID(),
        //   value: t.MEDIO,
        //   group: t.ID_MEDIO,
        //   content: '  ',
        //   type: 'range',
        //   start: t.INICIO_JORNADA,
        //   end: t.INICIO_JORNADA,
        //   style: 'border: solid 1px #43d400!important; border-top: solid 5px #43d400!important; border-bottom: solid 5px #43d400!important;',
        //   className: 'inicioJornadaLinea',
        //   title: 'Inicio jornada'
        // }
        // items.push(item)

        if (t.FECHA_ALARMADO) {
          // console.log(t)
          let start = t.FECHA_ALARMADO
          let end = this.horaFin(t, 0)
          // console.log('Start: ', start)
          // console.log('End: ', end)
          // Ida a incendio
          tiempo = end // t.FECHA_EN_CAMINO ? t.FECHA_EN_CAMINO.diff(t.FECHA_ALARMADO, 'minutes') : this.$date.now().diff(t.FECHA_ALARMADO, 'minutes')
          item = {
            id: this.$uuid.createUUID(),
            value: t.MEDIO,
            group: t.ID_MEDIO,
            content: 'Alarmado',
            start: start,
            end: end,
            className: 'demora',
            title: (tiempo.$H < 9 ? '0' : '') + tiempo.$H + ':' + (tiempo.$m < 9 ? '0' : '') + tiempo.$m + ':' + (tiempo.$s < 9 ? '0' : '') + tiempo.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
          }
          items.push(item)

          if (t.FECHA_EN_CAMINO) { // FECHA_MAX_ACTUACION
            start = end
            end = this.horaFin(t, 1)

            // En camino
            tiempo = t.FECHA_EN_CAMINO.diff(t.FECHA_EN_CAMINO, 'minutes') // FECHA_MAX_ACTUACION
            item = {
              id: this.$uuid.createUUID(),
              value: t.MEDIO,
              group: t.ID_MEDIO,
              content: 'En camino',
              start: start,
              end: end,
              className: 'transito',
              title: (end.$H < 9 ? '0' : '') + end.$H + ':' + (end.$m < 9 ? '0' : '') + end.$m + ':' + (end.$s < 9 ? '0' : '') + end.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
            }
            items.push(item)
          }

          if (t.FECHA_FINALIZADO) {
            start = end
            end = this.horaFin(t, 2)
            // Incendio / En lugar
            tiempo = t.FECHA_FINALIZADO.diff(t.FECHA_EN_LUGAR, 'minutes') // FECHA_MAX_ACTUACION
            item = {
              id: this.$uuid.createUUID(),
              value: t.MEDIO,
              group: t.ID_MEDIO,
              content: 'Incendio',
              start: start,
              end: end,
              className: 'incendio',
              title: (end.$H < 9 ? '0' : '') + end.$H + ':' + (end.$m < 9 ? '0' : '') + end.$m + ':' + (end.$s < 9 ? '0' : '') + end.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
            }
            items.push(item)
          }

          // Descanso
          // if (t.FECHA_FIN_DESCANSO) {
          //   start = end
          //   end = this.horaFin(t, 3)
          //   tiempo = t.FECHA_FIN_DESCANSO.diff(t.FECHA_FINALIZADO, 'minutes')
          //   item = {
          //     id: this.$uuid.createUUID(),
          //     value: t.MEDIO,
          //     group: t.ID_MEDIO,
          //     content: 'Descanso',
          //     start: start,
          //     end: end,
          //     className: 'descanso',
          //     title: (end.$H < 9 ? '0' : '') + end.$H + ':' + (end.$m < 9 ? '0' : '') + end.$m + ':' + (end.$s < 9 ? '0' : '') + end.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
          //   }
          //   items.push(item)
          // }

          // TODO: Descomentar para la planif a futuro.
          if (this.getNumActuacionesMedio(t) === t.ACTUACION) {
            let t2 = Object.assign({}, t)
            let itemsPlanif = this.getPlanifItem(t2)

            for (let j = 0; j < itemsPlanif.length; j++) {
              items.push(itemsPlanif[j])
            }
          }
        }
      }

      return items
    },

    horaFin (t, estado) {
      if (t.FECHA_EN_CAMINO && estado === 0) {
        return t.FECHA_EN_CAMINO
      }

      if (t.FECHA_EN_LUGAR && estado <= 1) {
        return t.FECHA_EN_LUGAR
      }

      if (t.FECHA_FINALIZADO && estado <= 2) {
        return t.FECHA_FINALIZADO
      }

      if (t.FECHA_FIN_DESCANSO && estado <= 3) {
        return t.FECHA_FIN_DESCANSO
      }

      return this.$date.now()
    },

    horaAlarmado (t) {
      console.log(t)
      if (t.FECHA_EN_CAMINO) {
        return t.FECHA_EN_CAMINO
      }
      if (t.FECHA_EN_LUGAR) {
        return t.FECHA_EN_LUGAR
      }

      return t.FECHA_FINALIZADO ? t.FECHA_FINALIZADO : this.$date.now()
    },

    horaEnCamino (t) {
      if (t.FECHA_EN_LUGAR) {
        return t.FECHA_EN_LUGAR
      }

      return t.FECHA_FINALIZADO ? t.FECHA_FINALIZADO : this.$date.now()
    },

    horaFinalizado (t) {
      return t.FECHA_FINALIZADO ? t.FECHA_FINALIZADO : this.$date.now()
    },

    getPlanifItem (t) {
      let items = []
      let item
      let tiempo

      // Horas de alarmado
      let horaAlarmado = this.horaAlarmado(t).diff(t.INICIO_JORNADA, 'hours', true)

      let horaEnCamino = this.horaEnCamino(t).diff(t.INICIO_JORNADA, 'hours', true) - horaAlarmado > 0 ? this.horaEnCamino(t).diff(t.INICIO_JORNADA, 'hours', true) - horaAlarmado : 0
      // let horaEnLugar = this.horaFinalizado(t).diff(t.INICIO_JORNADA, 'hours', true) - horaAlarmado - horaEnCamino > 0 ? this.horaFinalizado(t).diff(t.INICIO_JORNADA, 'hours', true) - horaAlarmado - horaEnCamino : 0

      // console.log('Medio: ', t.MEDIO)
      // console.log('Hora Alarmado: ', horaAlarmado)
      // console.log('Hora en camino: ', horaEnCamino)

      let horasIncendio = t.TIEMPO_OPERATIVO - (horaEnCamino + horaAlarmado)

      // console.log('Tiempo operativo: ', t.TIEMPO_OPERATIVO, (horaEnCamino + horaAlarmado))

      // if (horasIncendio < 0) {
      //   horasIncendio = 0
      //   item = {
      //     id: this.$uuid.createUUID(),
      //     value: t.MEDIO,
      //     group: t.ID_MEDIO,
      //     content: 'No llega incendio',
      //     start: t.FECHA_FINALIZADO > this.$date.now() ? t.FECHA_FINALIZADO : this.$date.now(),
      //     end: t.FECHA_ALARMADO > this.$date.now() ? t.FECHA_ALARMADO.add(t.TIEMPO_OPERATIVO, 'hours') : this.$date.now().add(t.TIEMPO_OPERATIVO, 'hours'), // FECHA_MAX_ACTUACION
      //     className: 'demora',
      //     title: (t.FECHA_ALARMADO.$H < 9 ? '0' : '') + t.FECHA_ALARMADO.$H + ':' + (t.FECHA_ALARMADO.$m < 9 ? '0' : '') + t.FECHA_ALARMADO.$m + ':' + (t.FECHA_ALARMADO.$s < 9 ? '0' : '') + t.FECHA_ALARMADO.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
      //   }
      //   items.push(item)
      //   return items
      // }

      // console.log('Horas incendio: ', horasIncendio)

      t.INICIO_JORNADA = this.horaFin(t, 3) > this.$date.now() ? this.horaFin(t, 3) : this.$date.now()
      t.FECHA_EN_CAMINO = t.INICIO_JORNADA.add(horaAlarmado, 'hours', true)

      // Alarmado
      tiempo = t.FECHA_EN_CAMINO ? t.FECHA_EN_CAMINO : this.$date.now()
      item = {
        id: this.$uuid.createUUID(),
        value: t.MEDIO,
        group: t.ID_MEDIO,
        content: 'Alarmado planif',
        start: t.INICIO_JORNADA,
        end: t.FECHA_EN_CAMINO,
        className: 'demora',
        title: (tiempo.$H < 9 ? '0' : '') + tiempo.$H + ':' + (tiempo.$m < 9 ? '0' : '') + tiempo.$m + ':' + (tiempo.$s < 9 ? '0' : '') + tiempo.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
      }
      items.push(item)

      // En camino
      // tiempo = t.FECHA_EN_LUGAR.diff(t.FECHA_EN_CAMINO, 'minutes') // FECHA_MAX_ACTUACION
      let end = t.FECHA_EN_CAMINO.add(horaEnCamino, 'hours', true)
      item = {
        id: this.$uuid.createUUID(),
        value: t.MEDIO,
        group: t.ID_MEDIO,
        content: 'En camino planif',
        start: t.FECHA_EN_CAMINO,
        end: end, // FECHA_MAX_ACTUACION
        className: 'transito',
        title: (end.$H < 9 ? '0' : '') + end.$H + ':' + (end.$m < 9 ? '0' : '') + end.$m + ':' + (end.$s < 9 ? '0' : '') + end.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
      }
      items.push(item)

      // Incendio / En lugar
      tiempo = t.FECHA_FINALIZADO.diff(t.FECHA_EN_LUGAR, 'minutes') // FECHA_MAX_ACTUACION
      item = {
        id: this.$uuid.createUUID(),
        value: t.MEDIO,
        group: t.ID_MEDIO,
        content: 'Incendio planif',
        start: t.FECHA_EN_CAMINO.add(horaEnCamino, 'hours', true),
        end: t.FECHA_EN_CAMINO.add(horasIncendio, 'hours', true).add(horaEnCamino, 'hours', true), // FECHA_MAX_ACTUACION
        className: 'incendio',
        title: (t.FECHA_FINALIZADO.$H < 9 ? '0' : '') + t.FECHA_FINALIZADO.$H + ':' + (t.FECHA_FINALIZADO.$m < 9 ? '0' : '') + t.FECHA_FINALIZADO.$m + ':' + (t.FECHA_FINALIZADO.$s < 9 ? '0' : '') + t.FECHA_FINALIZADO.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
      }
      items.push(item)

      // // Descanso
      // tiempo = t.FECHA_FIN_DESCANSO.diff(t.FECHA_FINALIZADO, 'minutes')
      // item = {
      //   id: this.$uuid.createUUID(),
      //   value: t.MEDIO,
      //   group: t.ID_MEDIO,
      //   content: 'Descanso',
      //   start: t.FECHA_EN_CAMINO.add(horasIncendio, 'hours').add(horaEnCamino, 'hours'),
      //   end: t.FECHA_EN_CAMINO.add(horasIncendio, 'hours').add(horaEnCamino, 'hours').add(t.TIEMPO_DESCANSO, 'hours'),
      //   className: 'descanso',
      //   title: (t.FECHA_FIN_DESCANSO.$H < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$H + ':' + (t.FECHA_FIN_DESCANSO.$m < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$m + ':' + (t.FECHA_FIN_DESCANSO.$s < 9 ? '0' : '') + t.FECHA_FIN_DESCANSO.$s // tiempo / 60 >= 1 ? Math.floor(tiempo / 60) + 'h ' + tiempo % 60 + 'min' : tiempo + 'min'
      // }
      // items.push(item)

      return items
    },

    getNumActuacionesMedio (t) {
      return this.mediosAsignados.filter(x => x.ID_MEDIO === t.ID_MEDIO).length
    },

    optionsTimeLine () {
      let orto = getSunrise(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, new Date())
      let ocaso = getSunset(this.incendioSelected.LATITUD, this.incendioSelected.LONGITUD, new Date())

      let minTimeLine = this.$date.parseDate(this.incendioSelected.FECHA_CREACION) // .subtract(this.numDiasPasado, 'days')
      let maxTimeLine = this.$date.parseDate(ocaso).add(this.numDiasFuturo, 'days')

      let initTimeLine = minTimeLine // this.$date.now().subtract(1, 'days')
      let endTimeLine = this.$date.now().add(12, 'hours')

      let options = {
        groupOrder: (a, b) => {
          return a.initTime < b.initTime ? -1 : 1
        },

        stack: false,
        showTooltips: true,
        tooltip: {
          followMouse: true,
          overflowMethod: 'cap'
        },
        orientation: 'top',
        zoomMin: 100000,
        maxHeight: 'calc(70vh - 100px)',
        verticalScroll: true,
        zoomKey: 'ctrlKey',
        min: minTimeLine.valueOf(),
        max: maxTimeLine.valueOf(),
        start: initTimeLine.valueOf(),
        end: endTimeLine.valueOf()
        // editable: true,
        // stackSubgroups: false // TODO: Edit custom to guapo.
      }
      return options
    },

    fitTimeline () {
      if (this.mediosAsignados.length > 0) {
        this.timeline.fit()
      }
    },

    /* Filtros */
    fechahora (value) {
      return value ? this.$date.formatDate(value, 'DD/MM/YYYY HH:mm') : null
    },
    hhmm (value) {
      return value ? this.$date.formatDate(value, 'HH:mm') : null
    },
    horasmin (value) {
      return value ? Math.floor(value / 60) + 'h ' + value % 60 + 'min' : null
    },
    min (value) {
      let min = null
      if (value) {
        const hhmm = value.split(':')
        min = hhmm[0] * 60 + parseInt(hhmm[1])
      }
      return min
    }
  },

  beforeDestroy () {
    if (this.timeline) {
      this.timeline = null
    }

    clearTimeout(this.timeout)
    clearInterval(this.timer)
  },

  mounted () {
    this.init()
  }
}
</script>

<style scoped>
  @import '../../../node_modules/vis-timeline/dist/vis-timeline-graph2d.min.css'; /*TODO: Nose si hace algo*/

  /* .tabs__items{
    height: 63vh;
    overflow-y: auto;
  } */

  /* Colores TODO: repetido en MMTTT */
  ::v-deep .vis-item.transito  { background-color: #FFC400; }
  ::v-deep .vis-item.incendio  { background-color: #FF0000; }
  ::v-deep .vis-item.descanso { background-color: #FFFF00; }
  ::v-deep .vis-item.demora { background-color: #00AABB; }
  ::v-deep .vis-item.background-dia { background-color: rgba(170, 255, 248, 0.541); }
  ::v-deep .vis-item.background-noche { background-color: rgba(235, 170, 255, 0.329); }
  ::v-deep .vis-item.inicioJornadaLinea { background-color: #43d400; border-right: solid 3px #43d400;}

  ::v-deep .vis-timeline, .vis-bottom {
    background: white;
  }

  ::v-deep .vis-item.transito-plan  { background-color: rgba(255, 196, 0, 0.466); }
  ::v-deep .vis-item.incendio-plan  { background-color: rgba(255, 0, 0, 0.479); }
  ::v-deep .vis-item.descanso-plan { background-color: rgba(255, 255, 0, 0.479); }
  ::v-deep .vis-item.demora-plan { background-color: rgba(0, 171, 187, 0.521); }

</style>
