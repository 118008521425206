<template>
  <v-dialog v-model="show" persistent max-width="420px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Nivel del incendio</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form v-model="valid">
          <v-container fluid>
            <!-- Aviso -->
            <v-row class="pt-2">
              <v-col>
                <v-select
                  :items="tiposIncendioNivel" item-text="INCENDIO_NIVEL" v-model="nivel"
                  label="Nivel del incendio" outlined hide-details dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <vx-date-text-edit
                  v-model="fechaInicio" :min="fechaInicioMin" :disabled="fechaInicioDisabled" label="Fecha de inicio" not-future
                  outlined required dense
                />
              </v-col>

              <v-col sm="6">
                <vx-date-text-edit v-model="fechaFin" label="Fecha de fin" :min="fechaFinMin" :disabled="fechaFinDisabled" not-future outlined dense />
              </v-col>
            </v-row>
            <v-row />
          </v-container>
        </v-form> <!-- Llegada -->
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="acceptDisable " @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
/* eslint-disable */

export default {
  props: {
    show: Boolean,
    idIncendio: String,
  },

  data: () => ({
    valid: true,
    nivelesPosibles: ["Nivel 1", "Nivel 2"],
    nivel: null,
    fechaInicio: null,
    fechaFin: null,
    dateFormat: 'DD/MM/YYYY HH:mm',
    estadoIncendio: null,
    fechaFinMin : null

  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },
    ...mapGetters ('incendio', [
      'tiposIncendioNivel'
    ]),
    fechaInicioMin (){
      let fechaMin = null
      if(this.incendio){
        fechaMin = this.incendio.NIVELES.length == 0 ? this.$date.parseDate(this.incendio.ESTADOS[this.incendio.ESTADOS.length - 1].FECHA) : this.$date.parseDate(this.incendio.NIVELES[0].FECHA_FIN)
        this.fechaFinMinima = fechaMin.add(1, 'minute')
      }
      return fechaMin
    },
    acceptDisable (){
      return (!this.nivel || !this.fechaInicio) || !this.valid
    },
    fechaInicioDisabled(){
      return !this.nivel
    },
    fechaFinDisabled(){
      return !this.valid && !this.fechaFin
    
    }

  },

  watch: {

    show () {
      if (this.show) {
        this.fechaInicioMinima = this.$date.parseDate(this.incendio.ESTADOS[this.incendio.ESTADOS.length - 1].FECHA)
        this.fechaFinMinima = this.fechaInicioMinima.add(1, 'minute')

        this.nivel = null
        this.fechaInicio = null
        this.fechaFin = null
      }
    },
    valid(newVal){
      if(newVal){
        let fechaInicioParsed = this.$date.parseDate(this.fechaInicio)
        this.fechaFinMin = fechaInicioParsed.add(1, 'minute')

      }
    },
  },

  methods: {

    cancelar () {
      this.$emit('cancelar')
    },

    aceptar () {
      let estadoAdd
      for (let i = 0; i < this.tiposIncendioNivel.length; i++) {
        if (this.nivel === this.tiposIncendioNivel[i].INCENDIO_NIVEL) {
          estadoAdd = this.tiposIncendioNivel[i]
          break
        }
      }
      this.estadoIncendio = {
        ID_INCENDIO_NIVEL: this.$uuid.createUUID(),
        ID_INCENDIO: this.idIncendio,
        ID_TIPO_INCENDIO_NIVEL: estadoAdd.ID_TIPO_INCENDIO_NIVEL,
        FECHA_INICIO: this.$date.parseDate(this.fechaInicio),
        FECHA_FIN: this.$date.parseDate(this.fechaFin),
        TIPO_INCENDIO_NIVEL: estadoAdd
      }
      this.$store.dispatch('incendio/addNivelIncendio', this.estadoIncendio)
      this.$emit('redrawIncendio')
      this.$emit('aceptar')

    }
  }
}
</script>

<style scoped>

</style>
