<template>
  <div>
    <v-text-field v-model="search" class="mt-2" label="Buscar" append-icon="mdi-magnify" />

    <v-btn-toggle v-model="categoriasSelecionadas" color="primary" rounded multiple>
      <v-btn :key="i" v-for="(tipo,i) in categoriasMedios" small>{{ tipo.text }}</v-btn>
    </v-btn-toggle>

    <v-data-table
      v-model="mediosSelected" :headers="headers" :items="mediosFiltrados" item-key="ID_MEDIO" :search="search" height="630px" :item-class="getRowColor" @click:row="zoomMedio"
      group-by="ID_INCENDIO" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" disable-pagination hide-default-footer :headers-length="6" style="white-space: normal"
    >
      <template #no-data>
        <p>Datos no disponibles.</p>
      </template>

      <template #[`item.MEDIO`]="{ item }">
        <span>{{ item.MEDIO.substr(0,15) + (item.MEDIO.length > 15 ? '.' : '') }}</span>
      </template>

      <template #[`item.ULT_POSICION`]="{ item }">
        <span>{{ item.ULT_POSICION ? item.ULT_POSICION.FECHA : '' | formatDate }}</span>
      </template>

      <template #[`item.ESTADO`]="{ item }">
        <span>{{ nombreEstado(item.ESTADO) }}</span>
      </template>

      <template #[`item.FECHA`]="{ item }">
        <span>{{ item.FECHA ? item.FECHA : item.FECHA_FINALIZADO | formatDate }}</span>
      </template>

      <template #[`item.ID_INCENDIO_ANTERIOR`]="{ item }">
        <span>{{ $store.getters['incendio/getIncendioByIDAll'](item.ID_INCENDIO_ANTERIOR) ? $store.getters['incendio/getIncendioByIDAll'](item.ID_INCENDIO_ANTERIOR).MUNICIPIO : 'No ha actuado' }}</span>
      </template>

      <template #[`group.header`]="{ group, toggle, isOpen }">
        <td @click="toggle" :colspan="headers.length" style="cursor: pointer;">
          <v-btn x-small icon :ref="group" :style="getGroupHeaderColor (group)">
            <v-icon v-if="!isOpen">mdi-plus</v-icon>
            <v-icon v-else>mdi-minus</v-icon>
          </v-btn>
          <span>     {{ $store.getters['incendio/getIncendioByIDAll'](group) ? $store.getters['incendio/getIncendioByIDAll'](group).MUNICIPIO : 'Sin asignar' }}</span>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import constants from '../../helpers/constants'
import * as ArcGIS from '../../helpers/ArcGIS'

export default {
  props: {
    show: Boolean
  },

  data: () => ({
    isValid: false,

    sortBy: 'ULT_POSICION',
    sortDesc: true,

    headers: [
      { text: 'Nombre', value: 'MEDIO', align: 'left', sortable: true, width: '30%' },
      { text: 'Tipo', value: 'TIPO', align: 'left', sortable: true },
      {
        text: 'Fecha ult. posición',
        value: 'ULT_POSICION',
        align: 'left',
        sortable: true,
        sort: (a, b) => {
          if (!a || !a.FECHA) return -1
          if (!b || !b.FECHA) return 1
          return new Date(a.FECHA) - new Date(b.FECHA)
        }
      },

      { text: 'Estado', value: 'ESTADO', align: 'left', sortable: true },
      {
        text: 'Fecha ult. estado',
        value: 'FECHA',
        align: 'left',
        sortable: true,
        sort: (a, b) => {
          if (!a) return -1
          if (!b) return 1
          return new Date(a) - new Date(b)
        }
      },
      { text: 'Ultimo incendio', value: 'ID_INCENDIO_ANTERIOR', align: 'left', sortable: true }
    ],

    search: '',

    categoriasMedios: [
      { text: 'Aéreos', value: 'Aéreo', align: 'left', sortable: true },
      { text: 'Terrestres', value: 'Terrestre', align: 'left', sortable: true },
      { text: 'Recursos', value: 'Recurso', align: 'left', sortable: true }
    ],

    fechaAviso: null,
    dateMin: null,

    mediosSelected: [],

    idSector: null,

    mediosOrder: [],

    mediosFiltrados: [],

    estadoMedios: [],

    categoriasSelecionadas: [0, 1, 2]
  }),

  computed: {
    disableAdd () {
      return this.mediosSelected.length === 0 || !this.isValid
    },

    medios () {
      let medios = JSON.parse(JSON.stringify(this.$store.getters['medio/medios']))

      let incendios = this.$store.getters['incendio/incendios'] // TODO: esta chapuza puede dar problemas aun mayores si se oculta el incendio antes de que acabe el descanso
      // console.log('MEDIOS', medios.filter(x => x.MEDIO === '113'))
      /** TODO: Esta cosa super cutre se hace para tener la fecha de fin descanso mas tardia en DialogAddMedios */
      medios.forEach((m) => { // Añadir descanso a cada medio de la lista
        let fechaDescanso = this.$date.parseDate('2018-01-01 00:00:00.000')
        incendios.forEach((incendio) => {
          for (let i = 0; i < incendio.SECTORES.length; i++) {
            let sector = incendio.SECTORES[i]

            for (let j = 0; j < sector.MEDIOS.length; j++) {
              let medioSector = sector.MEDIOS[j]

              if (medioSector.ID_MEDIO.equalsIgnoreCase(m.ID_MEDIO)) {
                if (medioSector.FECHA_FIN_DESCANSO) {
                  let newFechaDescanso = this.$date.parseDate(medioSector.FECHA_FIN_DESCANSO, 'YYYY-MM-DDTHH:mm:ss.SSSZ')

                  if (newFechaDescanso > fechaDescanso) {
                    fechaDescanso = newFechaDescanso
                  }
                }
              }
            }
          }
        })

        if (fechaDescanso.format('YYYY/MM/DD HH:mm') !== '2018/01/01 00:00') {
          m.FECHA_FIN_DESCANSO = fechaDescanso
        }
      })

      return medios
    },

    sectores () {
      let sectores = []

      if (this.incendio) {
        sectores = this.incendio.SECTORES
      }

      return sectores
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.fechaAviso = this.$date.currentDate()
        this.idSector = this.sectores[0].ID_SECTOR

        this.dateMin = this.$date.parseDate(this.incendio.FECHA_CREACION)

        this.orderMedios()
      }
    },

    medios () {
      this.orderMedios()
    },

    categoriasSelecionadas () {
      this.mediosFiltrados = this.mediosOrder.filter((a) => {
        let valor = -1

        switch (a.CATEGORIA) {
          case 'Aéreo':
            valor = 0
            break
          case 'Terrestre':
            valor = 1
            break
          case 'Recurso':
            valor = 2
            break
        }

        return this.categoriasSelecionadas.includes(valor)
      })
    }
  },

  methods: {

    disabledPorFinDescanso (medio) {
      let disabled = false

      if (medio.FECHA_FIN_DESCANSO) {
        let now = this.$date.now()
        let fechaFinDescanso = this.$date.parseDate(medio.FECHA_FIN_DESCANSO)
        disabled = fechaFinDescanso > now
      }
      return disabled
    },

    getRowColor (medio) { // TODO: esto es muy cutre pero no se como hacerlo de otra forma
      let rowClass = ''
      switch (medio.ESTADO) {
        case 1:
          rowClass = 'medioAvisoResumen'
          break
        case 2:
          rowClass = 'medioLlegadaIncResumen'
          break
        case 3:
          rowClass = 'medioSalidaResumen'
          break
      }

      if (this.disabledPorFinDescanso(medio)) {
        rowClass = 'medioDescansoResumen'
      }

      return rowClass
    },

    getGroupHeaderColor (incendio) {
      let estilo = {
        cursor: 'pointer',
        'background-color': this.$store.getters['incendio/getIncendioByID'](incendio) ? this.$store.getters['incendio/getIncendioByID'](incendio).ESTADOS[0].TIPO_INCENDIO_ESTADO.COLOR : 'green'
      }

      return estilo
    },

    orderMedios () {
      let mediosOrder = JSON.parse(JSON.stringify(this.medios))
      /* for (let i = 0; i < mediosOrder.length; i++) { // TODO: revisar que es esto
        let m = mediosOrder[i]
        if (m.ESTADO === 3) {
          m.ESTADO = 0
        }
      } */

      mediosOrder.sort(function (a, b) {
        let aEstado = a.ESTADO
        let bEstado = b.ESTADO
        let aMedio = a.MEDIO.toUpperCase()
        let bMedio = b.MEDIO.toUpperCase()

        if (aEstado === bEstado) {
          return (aMedio < bMedio) ? -1 : (aMedio > bMedio) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      this.mediosOrder = mediosOrder
      this.mediosFiltrados = mediosOrder
    },

    zoomMedio (value) {
      let medio = this.mediosOrder.find(x => x.ID_MEDIO === value.ID_MEDIO)
      if (medio && medio.ULT_POSICION) {
        let center = {
          target: [medio.ULT_POSICION.LONGITUD, medio.ULT_POSICION.LATITUD],
          zoom: 17
        }

        ArcGIS.setCenterMap(center)
      }
    },

    nombreEstado (estado) {
      switch (estado) {
        case 24:
          return 'Alarmado'

        case 7:
          return 'En camino'

        case 1:
          return 'En lugar'

        case 19:
          return 'Finalizado'
      }
    }
  },

  created () {
    this.estadoMedios = constants.estadosMedio
  }
}
</script>

<style>
  .medioAvisoResumen {
    background-color: #8EB4E3 !important;
  }
  .medioLlegadaIncResumen {
    background-color: #F14D5A !important;
  }
  .medioSalidaResumen {
    background-color: #FFC000 !important;
  }
  .medioDescansoResumen {
    background-color: #AAAAAA !important;
  }

</style>
