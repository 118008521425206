<template>
  <v-dialog v-model="show" persistent max-width="590px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-form v-model="isValid">
            <v-row>
              <v-col>
                <v-text-field v-model="periodoAnterior.MEDIO" label="Medio" outlined dense readonly />
              </v-col>
              <v-col>
                <v-text-field v-model="periodoAnterior.TIPO" label="Tipo" outlined dense readonly />
              </v-col>
              <!--<v-layout column>
                <v-flex xs12 sm6 md6>
                  <v-text-field label="Reincorporación" v-model="fechaReincorporacion" readonly></v-text-field>
                </v-flex>
              </v-layout>-->
            </v-row>

            <v-row dense>
              <v-col>
                <!--<v-text-field label="Reincorporación" v-model="fechaReincorporacion" readonly></v-text-field>-->
                <span v-show="errorReincorporacion" style="color:red">Esta reincorporación no cumple con los tiempos calculados.</span>

                <vx-date-text-edit v-model="inicioJornada" label="Inicio jornada" outlined dense required />
                <!-- <VueCtkDateTimePicker id="inicioJornadaActDatePicker"
                                    label="Inicio jornada"
                                    color="#00759e"
                                    format="YYYY-MM-DD HH:mm"
                                    noClearButton
                                    overlay
                                    position="bottom"
                                    :error="this.errorDate"
                                    v-on:is-shown="abrirDatePicker('inicioJornadaActDatePicker')"
                                    v-on:is-hidden="cerrarDatePicker('inicioJornadaActDatePicker')"
                                    button-now-translation="Ahora"
                                    v-model="inicioJornada"
                                    ref="inicioJornadaActDatePicker">
                </VueCtkDateTimePicker> -->
              </v-col>
              <v-col>
                <vx-date-text-edit v-model="fechaReincorporacion" label="Reincorporación" outlined dense required />
                <!-- <VueCtkDateTimePicker id="reincorporacionDatePicker"
                                    label="Reincorporación"
                                    color="#00759e"
                                    format="YYYY-MM-DD HH:mm"
                                    noClearButton
                                    overlay
                                    position="bottom"
                                    button-now-translation="Ahora"
                                    v-model="fechaReincorporacion"
                                    :error="this.errorReincorporacion"
                                    v-on:is-shown="abrirDatePicker('reincorporacionDatePicker')"
                                    v-on:is-hidden="cerrarDatePicker('reincorporacionDatePicker')"
                                    ref="reincorporacionDatePicker"/> -->
              </v-col>
              <v-col>
                <vx-date-text-edit v-model="fechaLlegadaIncendioCalculada" label="Llegada a incendio" outlined dense required />
                <!-- <VueCtkDateTimePicker id="llegadaIncendioDatePicker"
                                    label="Llegada a incendio"
                                    color="#00759e"
                                    format="YYYY-MM-DD HH:mm"
                                    noClearButton
                                    overlay
                                    position="bottom"
                                    button-now-translation="Ahora"
                                    v-model="fechaLlegadaIncendioCalculada"
                                    :error="this.errorDate"
                                    v-on:is-shown="abrirDatePicker('llegadaIncendioDatePicker')"
                                    v-on:is-hidden="cerrarDatePicker('llegadaIncendioDatePicker')"
                                    ref="llegadaIncendioDatePicker"/> -->
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-text-field v-model="horasOperativo" label="Tiempo operativo (horas)" type="number" :min="1" outlined dense :rules="[globalRules.required]" />
              </v-col>
              <v-col>
                <v-text-field v-model="horasDescanso" label="Tiempo descanso (horas)" type="number" :min="1" outlined dense :rules="[globalRules.required]" />
              </v-col>

              <v-col>
                <v-text-field v-model="numComponentes" label="Nº componentes" type="number" :min="1" outlined dense :rules="[globalRules.required]" />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col>
                <v-select v-model="sectorSelected" :items="sectores" label="Sector" outlined dense item-text="SECTOR" item-value="ID_SECTOR" return-object autocomplete :rules="[globalRules.required]" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green darken-1" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogAddActuacionMMTT',

  props: {
    show: Boolean,
    isEdit: Boolean,
    editedItem: {
      type: Object,
      default: () => { return {} }
    },
    periodoAnterior: {
      type: Object,
      default: () => { return {} }
    },
    sectores: Array
  },

  data: () => ({
    titulo: 'Añadir actuación al medio',
    isValid: false,

    errorReincorporacion: false,

    numComponentes: 1,
    fechaReincorporacion: null,
    fechaLlegadaIncendioCalculada: null,
    horasOperativo: 17,
    horasDescanso: 12,

    // Primera incorporacion
    inicioJornada: null,
    horaMovilizacion: null,
    horaEnCamino: null,
    sectorSelected: null
  }),

  computed: {
    medios () {
      return this.$store.getters.getMedios.filter(x => x.CATEGORIA !== 'Aéreo').sort((a, b) => {
        return a.MEDIO - b.MEDIO
      })
    }
  },

  watch: {
    show () {
      if (this.show) {
        if (this.isEdit) {
          this.titulo = 'Editar actuación'

          this.numComponentes = this.editedItem.NUM_COMPONENTES
          this.sectorSelected = this.editedItem.SECTOR

          this.inicioJornada = this.$date.parseDate(this.editedItem.INICIO_JORNADA).format('DD/MM/YYYY HH:mm')
          this.horasOperativo = this.editedItem.TIEMPO_OPERATIVO
          this.horasDescanso = this.editedItem.TIEMPO_DESCANSO
        } else {
          this.titulo = 'Añadir actuación al medio'

          this.numComponentes = 1

          this.horasOperativo = parseInt(this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_ACTUACION'))
          this.horasDescanso = parseInt(this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_DESCANSO'))
          /* let fechaIncorporacion = moment().locale('es').format('YYYY-MM-DD')
            let horaEntrada = moment(this.periodoAnterior.INICIO_JORNADA).locale('es').format('HH:mm')
            this.inicioJornada = moment(fechaIncorporacion + ' ' + horaEntrada).locale('es').format('YYYY-MM-DD HH:mm') */
        }
      }
    },

    fechaReincorporacion () {
      if (!this.isEdit) {
        this.errorReincorporacion = this.periodoAnterior.FECHA_FIN_DESCANSO && (this.fechaReincorporacion !== this.periodoAnterior.FECHA_FIN_DESCANSO.format('DD/MM/YYYY HH:mm'))
      }
    },

    sectores () {
      this.sectorSelected = this.sectores[0]
    },

    periodoAnterior () { // Cuando cambia (lo ha recibido), copiarlo a otro elemento para no sobreescribir
      if (!this.periodoAnterior) return

      this.numComponentes = this.periodoAnterior.NUM_COMPONENTES

      if (this.isEdit) {
        this.fechaReincorporacion = this.editedItem.FECHA_ALARMADO.format('DD/MM/YYYY HH:mm')
        this.fechaLlegadaIncendioCalculada = this.editedItem.FECHA_EN_CAMINO ? this.editedItem.FECHA_EN_CAMINO.format('DD/MM/YYYY HH:mm') : this.fechaReincorporacion
      } else {
        if (this.periodoAnterior.FECHA_FIN_DESCANSO) {
          this.fechaReincorporacion = this.periodoAnterior.FECHA_FIN_DESCANSO.format('DD/MM/YYYY HH:mm') // DD/MM/YYYY HH:mm

          let fechaIncorporacion = this.$date.parseDate(this.fechaReincorporacion).format('DD/MM/YYYY')
          let horaEntrada = this.$date.parseDate(this.periodoAnterior.INICIO_JORNADA).format('HH:mm')
          this.inicioJornada = this.$date.parseDate(fechaIncorporacion + ' ' + horaEntrada).format('DD/MM/YYYY HH:mm')

          let itinere = this.periodoAnterior.FECHA_LLEGADA_BASE.diff(this.periodoAnterior.FECHA_EN_LUGAR, 'minutes') // FECHA_MAX_ACTUACION
          this.fechaLlegadaIncendioCalculada = this.$date.parseDate(this.fechaReincorporacion).add(itinere, 'minutes').format('DD/MM/YYYY HH:mm')
        }
      }
    }
  },

  filters: {
    fechahora (value) {
      if (value) {
        return this.$date.parseDate(value).format('DD/MM/YYYY HH:mm')
      }
    }
  },

  methods: {
    cancelar () {
      this.$emit('cancelar')
    },

    aceptar () {
      let horaEnCamino = this.$date.parseDate(this.fechaLlegadaIncendioCalculada)
      let inicioJornada = this.$date.parseDate(this.inicioJornada)

      if (!this.isEdit) { // Insertar nuevo
        let tiempoLlegadaIncendio = this.$date.parseDate(this.periodoAnterior.FECHA_FINALIZADO).diff(this.$date.parseDate(this.periodoAnterior.FECHA_MAX_ACTUACION, 'HH:mm'), 'minutes')

        // let horaEnCamino = moment(this.fechaReincorporacion).add(tiempoLlegadaIncendio, 'minutes') // moment(this.periodoAnterior.FECHA_FIN_DESCANSO).add(tiempoLlegadaIncendio, 'minutes')
        // let fechaMaximaActuacion = moment(this.fechaReincorporacion).add(this.horasOperativo, 'hours')
        let fechaMaximaActuacion = this.$date.parseDate(this.inicioJornada).add(this.horasOperativo, 'hours')
        let fechaEnLugar = this.$date.parseDate(fechaMaximaActuacion).subtract(tiempoLlegadaIncendio, 'minutes')
        let fechaFinalizado = this.$date.parseDate(fechaMaximaActuacion) // .add(tiempoLlegadaIncendio, 'minutes') // TODO: salida confirmada
        let fechaFinDescanso = this.$date.parseDate(fechaFinalizado).add(this.horasDescanso, 'hours') // TODO: llegada base confirmada

        let data = {
          ID_MEDIO: this.periodoAnterior.ID_MEDIO,
          ID_MEDIO_SECTOR: this.$uuid.createUUID(),
          MEDIO: this.periodoAnterior.MEDIO,
          ACTUACION: this.periodoAnterior.ACTUACION + 1,
          TIPO: this.periodoAnterior.TIPO,
          SECTOR: this.sectorSelected,
          NUM_COMPONENTES: this.numComponentes === '' ? '1' : this.numComponentes,
          INICIO_JORNADA: inicioJornada, // this.inicioJornada,
          TIEMPO_OPERATIVO: this.horasOperativo,
          TIEMPO_DESCANSO: this.horasDescanso,
          FECHA_INCORPORACION: this.$date.parseDate(this.fechaReincorporacion).format('YYYY/MM/DD'), // moment(this.periodoAnterior.FECHA_FIN_DESCANSO).format('YYYY/MM/DD'),
          FECHA_ALARMADO: this.$date.parseDate(this.fechaReincorporacion), // moment(this.periodoAnterior.FECHA_FIN_DESCANSO),
          FECHA_EN_CAMINO: horaEnCamino,
          FECHA_EN_LUGAR: fechaEnLugar,
          FECHA_MAX_ACTUACION: fechaMaximaActuacion,
          FECHA_FINALIZADO: fechaFinalizado,
          FECHA_FIN_DESCANSO: fechaFinDescanso
        }
        this.$emit('aceptar', data)
      } else { // Editar
        let data = { // Todo tiene que ser igual que en item menos numComp y sector
          ID_MEDIO: this.editedItem.ID_MEDIO,
          ID_MEDIO_SECTOR: this.editedItem.ID_MEDIO_SECTOR,
          MEDIO: this.editedItem.MEDIO,
          ACTUACION: this.editedItem.ACTUACION,
          TIPO: this.editedItem.TIPO,
          SECTOR: this.sectorSelected,
          NUM_COMPONENTES: this.numComponentes === '' ? '1' : this.numComponentes,
          INICIO_JORNADA: inicioJornada, // this.inicioJornada,
          TIEMPO_OPERATIVO: this.horasOperativo,
          TIEMPO_DESCANSO: this.horasDescanso,
          FECHA_INCORPORACION: this.$date.parseDate(this.fechaReincorporacion).format('DD/MM/YYYY'), // moment(this.editedItem.FECHA_FIN_DESCANSO).format('YYYY/MM/DD'),
          FECHA_ALARMADO: this.$date.parseDate(this.fechaReincorporacion), // moment(this.editedItem.FECHA_FIN_DESCANSO),
          FECHA_EN_CAMINO: horaEnCamino, // this.editedItem.FECHA_EN_CAMINO,
          FECHA_EN_LUGAR: this.editedItem.fechaEnLugar,
          FECHA_MAX_ACTUACION: this.editedItem.FECHA_MAX_ACTUACION,
          FECHA_FINALIZADO: this.editedItem.FECHA_FINALIZADO,
          FECHA_FIN_DESCANSO: this.editedItem.FECHA_FIN_DESCANSO
        }
        this.$emit('editar', data)
      }
    }
  },

  mounted () {
  }
}
</script>

<style scoped>
</style>
