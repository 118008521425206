<template>
  <v-dialog v-model="show" persistent max-width="350px">
    <v-card>
      <v-card-title class="pt-0 px-0">
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Editar estado: {{ nombreEstado }}</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-form v-model="isValid" @submit.prevent="aceptar">
          <vx-date-text-edit
            v-model="fechaEstado"
            label="Fecha"
            icon="esri-icon-calendar"
            :min="dateMin"
            :max="dateMax"
            required
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="red" text @click="cerrar">Cerrar</v-btn>
        <v-btn color="green" text :disabled="!isValid" @click="aceptar">
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    idIncendio: String,
    codigoTipoEstado: Number
  },

  data: () => ({
    isValid: false,

    fechaEstado: null,
    dateMin: null,
    dateMax: null
  }),

  watch: {
    show () {
      if (this.show) {
        this.getFechaEstadoIncendio()
      }
    }
  },

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    prevEstadoIncendio () {
      let estado = null

      if (this.incendio && this.codigoTipoEstado) {
        estado = this.incendio.ESTADOS.find(
          (x) => x.TIPO_INCENDIO_ESTADO.VALUE === this.codigoTipoEstado - 1
        )
      }

      return estado
    },

    postEstadoIncendio () {
      let estado = null

      if (
        this.incendio &&
        (this.codigoTipoEstado || this.codigoTipoEstado === 0)
      ) {
        estado = this.incendio.ESTADOS.find(
          (x) => x.TIPO_INCENDIO_ESTADO.VALUE === this.codigoTipoEstado + 1
        )
      }

      return estado
    },

    nombreEstado () {
      let estadoTipo = this.$store.getters[
        'incendio/getTipoIncendioEstadoByValue'
      ](this.codigoTipoEstado)

      if (estadoTipo) {
        return estadoTipo.INCENDIO_ESTADO
      }

      return null
    }
  },

  methods: {
    getFechaEstadoIncendio () {
      if (this.incendio) {
        /* && this.codigoTipoEstado (si es 0, da false) */
        let estadoIncendio = this.incendio.ESTADOS.find(
          (x) => x.TIPO_INCENDIO_ESTADO.VALUE === this.codigoTipoEstado
        )

        if (estadoIncendio) {
          this.fechaEstado = this.$date.formatDate(
            estadoIncendio.FECHA,
            'DD/MM/YYYY HH:mm'
          )

          /* this.dateMin = this.prevEstadoIncendio ? this.$date.parseDate(this.prevEstadoIncendio.FECHA) : null
          this.dateMax = this.postEstadoIncendio ? this.$date.parseDate(this.postEstadoIncendio.FECHA) : null */
          this.dateMin = this.prevEstadoIncendio
            ? this.$date
              .parseDate(this.prevEstadoIncendio.FECHA, new Date())
              .add(1, 'minute')
              .format('DD/MM/YYYY HH:mm')
            : null
          this.dateMax = this.postEstadoIncendio
            ? this.$date
              .parseDate(this.postEstadoIncendio.FECHA, new Date())
              .add(-1, 'minute')
              .format('DD/MM/YYYY HH:mm')
            : null

          if (this.codigoTipoEstado === 0) {
            // Inicio ( <= ahora )
            this.dateMax = this.postEstadoIncendio
              ? this.$date
                .parseDate(this.postEstadoIncendio.FECHA, new Date())
                .add(-1, 'minute')
                .format('DD/MM/YYYY HH:mm')
              : this.$date.currentDate()
          } else if (this.codigoTipoEstado === 4) {
            // Finalizado (restringe por la anterior y la actual)
            this.dateMax = this.$date.currentDate()
          }
        }
      } else {
        this.fechaEstado = null
        this.dateMin = null
        this.dateMax = null
      }
    },

    aceptar () {
      if (!this.isValid) {
        return
      }

      if (this.incendio) {
        /* && this.codigoTipoEstado (si es 0, da false) */
        let estadoIncendio = this.incendio.ESTADOS.find(
          (x) => x.TIPO_INCENDIO_ESTADO.VALUE === this.codigoTipoEstado
        )

        if (estadoIncendio) {
          let data = {
            ID_INCENDIO: this.idIncendio,
            ID_TIPO_INCENDIO_ESTADO: estadoIncendio.ID_TIPO_INCENDIO_ESTADO,
            CODIGO_TIPO_ESTADO: this.codigoTipoEstado,
            FECHA: this.$date.parseDate(this.fechaEstado)
          }

          this.$store.dispatch('incendio/editEstadoIncendio', data)
          this.$emit('redrawIncendio')
        }
      }

      this.cerrar()
    },

    cerrar () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
