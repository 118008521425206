import constants from './constants'
import VueInst from '../main'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export const pdfSimulacion = async function (captura, simulacion, informe) {
  let docDefinition = {
    pageOrientation: 'portrait',
    content: [

    ],
    styles: {
      header: {
        fontSize: 18,
        margin: [0, 0, 0, 10],
        bold: true
      },
      subheader: {
        fontSize: 12
        // bold: true
      },
      quote: {
        italics: true
      },
      small: {
        fontSize: 8
      }
    }
  }
  docDefinition.content.push(constants.headerPDF)

  docDefinition.content.push(

    {
      text: (simulacion.NOMBRE ? simulacion.NOMBRE.trim() : 'Sin nombre') + ' - ' + VueInst.$date.parseDate(simulacion.FECHA_CREACION).format('YYYY/MM/DD HH:mm'),
      style: 'header'
    },
    {
      text: 'Inicio: ' + VueInst.$date.parseDate(simulacion.FECHA_INICIO).format('YYYY/MM/DD HH:mm') + '. Número de horas: ' + simulacion.NUM_HORAS + '. Intervalo: ' + simulacion.INTERVALO + ' min.' + '\n\n', style: 'subheader'
    },
    // plan.DESCRIPCION + '\n\n',
    {
      image: captura,
      width: 500
    }
  )

  let inputInfo = []

  inputInfo.push([
    'Tiempo (min)',
    'Temperatura (ºC)',
    'Humedad relativa (%)',
    'Precipitaciones (%)',
    'Velocidad del viento (km/h)',
    'Dirección del viento (º)',
    'Nubes (%)',
    'Humedad combustible muerto fino (%)',
    'Humedad combustible muerto medio (%)',
    'Humedad combustible muerto grueso (%)',
    'Humedad combustible vivo (%)',
    'Humedad vegetal (%)',
    'Área (ha)'
  ])

  informe.data.forEach(element => {
    delete element.Date
    element.index = element.index * simulacion.INTERVALO
    element.Temp = element.Temp.toFixed(2)
    element.Wind_speed = element.Wind_speed.toFixed(2)
    element.Area = element.Area.toFixed(2)
    inputInfo.push(Object.values(element))
  })

  docDefinition.content.push({
    layout: 'lightHorizontalLines', // optional
    table: {
      headerRows: 1,
      body: inputInfo
    },
    margin: [0, 15, 0, 20],
    alignment: 'center',
    width: '*',
    style: {
      fontSize: 5
    }
  })

  pdfMake.createPdf(docDefinition).download((simulacion.NOMBRE ? simulacion.NOMBRE.trim() : 'Sin nombre') + '_' + VueInst.$date.parseDate(simulacion.FECHA_CREACION).format('YYYY_MM_DD_hh_mm'))
}
