import api from '@/api'

let Q = require('q')

const state = {
  parametros: []
}

const getters = {
  parametros: (state) => state.parametros,

  getParametro: (state) => (key) => {
    let parametro = null

    if (key) {
      parametro = state.parametros.find(x => x.CLAVE.equalsIgnoreCase(key))
    }
    return parametro ? parametro.VALOR : null
  }
}

const mutations = {
  SET_PARAMETROS (state, parametros) {
    state.parametros = parametros
  },

  SET_PARAMETRO (state, key, value) {
    if (key) {
      state.parametros.find(x => x.CLAVE.equalsIgnoreCase(key)).VALOR = value
    }
  }
}

const actions = {
  async fetchParametros ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.parametro.getParametros()
      commit('SET_PARAMETROS', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  async changeParametro ({ commit }, data) {
    let deferred = Q.defer()

    try {
      await api.parametro.setParametro(data.key, { VALOR: data.value })
      commit('SET_PARAMETRO', data.key, data.value)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
