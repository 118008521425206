<template>
  <div>
    <v-dialog v-model="showDialog" width="400px" persistent>
      <v-card>
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>{{ isEdit ? 'Editar' : 'Crear' }} perímetro</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pt-4">
          <v-form v-model="isValid">
            <v-text-field v-model="nombre" id="nombre" name="nombre" label="Nombre" outlined :rules="[globalRules.required]" />
            <v-textarea v-model="descripcion" label="Descripción" outlined :rules="[globalRules.required]" />
            <vx-date-text-edit v-model="fecha" label="Fecha" outlined required />
          </v-form>
          <v-btn text color="primary" @click="drawGraphicsOnMap">
            {{ isEdit ? 'Editar' : 'Crear' }} perímetro
            <v-icon>mdi-draw-pen</v-icon>
          </v-btn>
          <v-row align="center" justify="center" class="mt-3">
            <v-file-input
              v-model="perimetro"
              label="Introduzca el perímetro"
              variant="filled"
              prepend-icon="mdi-file-upload"
            />
            <v-btn text color="green" @click="subirPerimetro" :disabled="!perimetro">Subir</v-btn>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="red" v-if="isEdit" @click="deletePerimetro">Borrar</v-btn>
          <v-spacer />
          <v-btn text color="red" @click="cerrar">Cancelar</v-btn>
          <v-btn text color="green" :disabled="!isValid || graphicList.length === 0 || !aceptarCambios" @click="aceptar">Aceptar</v-btn>
        </v-card-actions>
      </v-card>

      <v-snackbar v-model="showSnackBar" :color="snackbarColor" :timeout="3000">
        <v-icon>mdi-alert</v-icon>
        {{ snackbarText }}
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import * as ArcGIS from '../../../helpers/ArcGIS'

export default {
  props: {
    show: Boolean,
    idIncendio: String,
    perimetroSelected: Object,
    isEdit: Boolean
  },

  data: () => ({
    showDialog: false,

    isValid: false,

    nombre: null,
    descripcion: null,
    fecha: null,

    graphicList: [],
    perimetro: null,

    aceptarCambios: false,
    nombreViejo: null,

    showSnackBar: false,
    snackbarColor: null,
    snackbarText: null
  }),

  watch: {
    show () {
      this.showDialog = this.show

      if (this.show) {
        if (this.isEdit) {
          this.nombre = this.perimetroSelected.NOMBRE
          this.descripcion = this.perimetroSelected.DESCRIPCION
          this.fecha = this.$date.formatDate(this.perimetroSelected.FECHA, 'DD/MM/YYYY HH:mm')

          this.graphicList = JSON.parse(JSON.stringify([...this.perimetroSelected.GRAFICOS_PERIMETRO]))
        } else {
          this.fecha = this.$date.currentDate()
        }
      }
    },

    // Watchers para habilitar el botón de aceptar en crear/editar perímetro
    nombre (nuevoNombre) {
      this.aceptarCambios = this.aceptarEdicion()
    },

    descripcion (nuevaDescripcion) {
      this.aceptarCambios = this.aceptarEdicion()
    },

    graphicList (nuevaGraphicList) {
      this.aceptarCambios = this.aceptarEdicion()
    }
  },

  methods: {
    async subirPerimetro () {
      let response

      response = await (ArcGIS.fileToGeoJSON(this.perimetro))
      if (response.status === 404) {
        this.snackbarError('Error al importar el perímetro.')
      } else {
        this.graphicList.push(...response)
        this.snackbarSuccess('Perímetro importado correctamente.')
      }
    },

    openUploadPerimeterDialog () {
      this.showDialog = true
    },

    drawGraphicsOnMap () {
      this.showDialog = false
      this.$eventHub.$emit('showPerimeterSymbology', { // Vamos a la clase PerimeterSymbology donde hacemos referencia a showPerimeterSymbology
        callback: this.onDrawGraphicsEnded,
        graphics: JSON.parse(JSON.stringify(this.graphicList))
      })
    },

    onDrawGraphicsEnded (graphics) {
      this.showDialog = true

      this.graphicList = graphics
    },

    aceptar () {
      if (!this.isEdit) { // Cuando se crea un perímetro
        this.addPerimetro()
      } else { // Cuando se edita un perímetro ya existente
        this.editPerimetro()
      }
      this.cerrar()
    },

    async addPerimetro () { // Método para crear un perímetro
      let date = this.$date.parseDate(this.fecha)
      let newPerimetro = {
        ID_PERIMETRO: this.$uuid.createUUID(),
        ID_INCENDIO: this.idIncendio,
        NOMBRE: this.nombre,
        DESCRIPCION: this.descripcion,
        GRAFICOS_PERIMETRO: this.graphicList,
        FECHA: date
      }

      // Convertir datos a modelo BD
      this.modelGraphicsList(newPerimetro)

      newPerimetro.measure = await ArcGIS.measurePerimetros(newPerimetro.GRAFICOS_PERIMETRO)

      this.$store.dispatch('incendio/addPerimetro', newPerimetro)
    },

    async editPerimetro () { // Método para editar el perímetro
      let date = this.$date.parseDate(this.fecha)
      let editPerimetro = {
        ID_PERIMETRO: this.perimetroSelected.ID_PERIMETRO,
        ID_INCENDIO: this.idIncendio,
        NOMBRE: this.nombre,
        DESCRIPCION: this.descripcion,
        GRAFICOS_PERIMETRO: this.graphicList,
        FECHA: date
      }
      this.modelGraphicsList(editPerimetro)

      editPerimetro.measure = await ArcGIS.measurePerimetros(editPerimetro.GRAFICOS_PERIMETRO)

      this.$store.dispatch('incendio/editPerimetro', editPerimetro)
    },

    deletePerimetro () { // Método para borrar el perímetro
      let msg = 'Borrando perímetro: ' + this.perimetroSelected.NOMBRE

      this.$root.$confirmDialog.open('¿Está seguro?', msg).then(result => {
        if (result) {
          this.$store.dispatch('incendio/deletePerimetro', this.perimetroSelected)

          this.cerrar()
        }
      })
    },

    modelGraphicsList (perimetro) {
      // Convertir datos a modelo BD
      for (let i = 0; i < perimetro.GRAFICOS_PERIMETRO.length; i++) {
        let g = perimetro.GRAFICOS_PERIMETRO[i]
        g.ID_GRAFICOS_PERIMETRO = g.attributes.GRAFICOS_PERIMETRO // TODO: attributes -> ATRIBUTOS
        g.ID_PERIMETRO = perimetro.ID_PERIMETRO
        g.type = g.type.toLowerCase()

        // FIX Parseo de colores
        if (g.symbol.color && g.symbol.color.toRgba) { // TODO: symbol -> SIMBOLO
          g.symbol.color = g.symbol.color.toRgba()
        }
        if (g.symbol.outline && g.symbol.outline.color.toRgba) {
          g.symbol.outline.color = g.symbol.outline.color.toRgba()
        }
      }

      return perimetro
    },

    resetVariables () { // Método que resetea todas las variables utilizadas
      this.nombre = null
      this.descripcion = null
      this.fecha = null
      this.graphicList = []
      this.perimetro = null
    },

    cerrar () { // Método que además de resetear, cierra la ventana cuando pulses el botón donde sea asignado
      this.resetVariables()
      this.$emit('close')
      ArcGIS.drawGraphicsPerimetro([])
    },

    aceptarEdicion () {
      /* CASOS DE USO
      Crear perímetro:
        El botón Aceptar aparecerá desactivado hasta que se rellene el campo nombre y descripción y se haya dibujado o importado como mínimo un perímetro.

      Editar perímetro:
        El botón Aceptar está desactivado si no se ha realizado ningún cambio. Si el nombre o la descripción cambian, el botón se activará (si los dejas vacíos te exige que los rellenes).
        Si modificas un perímetro ya creado o dibujas o importas uno nuevo, el botón se activará. Tiene que quedar como mínimo un perímetro, sino no se activará el botón.
      */
      if (this.isEdit) {
        return (this.perimetroSelected.NOMBRE !== this.nombre || this.perimetroSelected.DESCRIPCION !== this.descripcion ||
        JSON.stringify(this.perimetroSelected.GRAFICOS_PERIMETRO) !== JSON.stringify(this.graphicList))
      }
      return true
    },

    // Métodos para mostrar la notificación cuando importas un perímetro
    snackbarError (msg) {
      this.showSnackBar = true
      this.snackbarColor = 'error'
      this.snackbarText = msg
    },
    snackbarSuccess (msg) {
      this.showSnackBar = true
      this.snackbarColor = 'success'
      this.snackbarText = msg
    }

  }
}
</script>
