<template>
  <div>
    <v-card elevation="5">
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Planes de operaciones</v-toolbar-title>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right absolute @click="openAddPlanDialog" :disabled="disabledByRol">
              <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Añadir plan de operaciones</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text>
        <v-list expand subheader>
          <v-list-item v-for="plan in planesOperaciones" :key="plan.ID_PLAN_OPERACIONES">
            <v-list-item-content>
              <v-list-item-title>{{ plan.NOMBRE }}</v-list-item-title>
              <v-list-item-subtitle>{{ formatDate(plan.FECHA) }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :disabled="showDialog" @click="plan.ID_PLAN_OPERACIONES === idPlanShowing ? mostrarPlan(null) : mostrarPlan(plan)">
                    <v-icon color="black">{{ plan.ID_PLAN_OPERACIONES === idPlanShowing ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ plan.ID_PLAN_OPERACIONES === idPlanShowing ? 'Ocultar' : 'Visualizar' }}</span>
              </v-tooltip>
            </v-list-item-action>

            <v-list-item-action>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :disabled="showDialog || disabledByRol" @click="openEditPlanDialog(plan)">
                    <v-icon color="black">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-list-item-action>

            <v-list-item-action class="mx-0">
              <v-menu bottom right>
                <template #activator="{ on: onMenu }">
                  <v-tooltip bottom>
                    <template #activator="{ on: onTooltip }">
                      <v-btn icon v-on="{ ...onMenu, ...onTooltip }" :disabled="showDialog">
                        <v-icon color="black">mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar como...</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item @click="exportarPlanSHP(plan)">
                    <v-list-item-title>Descargar SHP</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="exportarPlanPDF(plan)">
                    <v-list-item-title>Descargar PDF</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
          <p v-if="planesOperaciones && planesOperaciones.length <= 0" align="center" style="margin: 16px 10px 10px 10px;">No hay ningún plan de operaciones.</p>
        </v-list>
      </v-card-text>
    </v-card>

    <vx-dialog-plan-operaciones :show="showDialog" :idIncendio="idIncendio" :planSelected="planSelected" :isEdit="isEdit" @open="showDialog = true" @close="showDialog = false" />
  </div>
</template>

<script>
import * as ArcGIS from '../../../helpers/ArcGIS'
import DialogPlanOperaciones from './DialogPlanOperaciones'

import constants from '../../../helpers/constants'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

let Q = require('q')

export default {
  props: {
    idIncendio: String
  },

  components: {
    'vx-dialog-plan-operaciones': DialogPlanOperaciones
  },

  data: () => ({
    showDialog: false,
    isEdit: false,

    idPlanShowing: null,
    planSelected: {}
  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    planesOperaciones () {
      let planes = []

      if (this.incendio) {
        planes = this.incendio.PLANES_OPERACIONES
      }
      return planes
    },
    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    }
  },

  watch: {
    showPlanesOperaciones () {
      if (this.showPlanesOperaciones) {
        this.idPlanShowing = null
      }
    },

    planesOperaciones () {
      if (this.idPlanShowing && this.planesOperaciones) {
        let plan = this.planesOperaciones.find(x => x.ID_PLAN_OPERACIONES === this.idPlanShowing)
        if (plan) {
          ArcGIS.drawGraphicsPlanOperaciones(this.planesOperaciones.find(x => x.ID_PLAN_OPERACIONES === this.idPlanShowing).GRAFICOS) // Repintar graficos del plan abierto
        }
      }
    }
  },

  methods: {
    formatDate (date) {
      return this.$date.formatDate(date, 'LLL')
    },

    openAddPlanDialog () {
      this.mostrarPlan(null)
      this.isEdit = false
      this.showDialog = true
    },

    openEditPlanDialog (plan) {
      this.mostrarPlan(null)
      this.planSelected = plan
      this.isEdit = true
      this.showDialog = true
    },

    mostrarPlan (plan) {
      let deferred = Q.defer()

      this.planSelected = plan

      if (plan) {
        this.idPlanShowing = plan.ID_PLAN_OPERACIONES
        this.$store.dispatch('shareMap/paintPlanOperaciones', plan.GRAFICOS)
        ArcGIS.drawGraphicsPlanOperaciones(plan.GRAFICOS).then(() => {
          deferred.resolve()
        })
      } else {
        this.idPlanShowing = null
        this.$store.dispatch('shareMap/paintPlanOperaciones', [])
        ArcGIS.drawGraphicsPlanOperaciones([]).then(() => {
          deferred.resolve()
        })
      }
      return deferred.promise
    },

    exportarPlanSHP (plan) {
      let defaultFeature = {
        type: 'Feature',
        geometry: {
          type: '',
          coordinates: {}
        },
        properties: {}
      }

      // Construir GEOJSON
      let features = []

      for (let i = 0; i < plan.GRAFICOS.length; i++) {
        let grafico = plan.GRAFICOS[i]

        let feature = JSON.parse(JSON.stringify(defaultFeature))

        feature.geometry.coordinates = ArcGIS.convertirMercXYToLatLon(grafico.coordenadas[0], grafico.coordenadas[1])

        feature.properties = grafico.attributes

        switch (grafico.type) {
          case 'point':
          case 'icon':
          case 'text':
            feature.geometry.type = 'Point'
            break
          case 'polyline':
            feature.geometry.type = 'LineString'
            break
          case 'polygon':
            feature.geometry.type = 'Polygon'
            break
        }
        features.push(feature)
      }

      let geoJson = {
        type: 'FeatureCollection',
        features: features
      }

      // Fichero SHP
      let shpwrite = require('shp-write-update')
      let options = {
        folder: plan.NOMBRE + '_' + plan.FECHA,
        types: {
          point: 'PUNTOS',
          polygon: 'POLIGONOS',
          line: 'LINEAS',
          polyline: 'POLYLINE'
        }
      }
      shpwrite.download(geoJson, options)
    },

    async exportarPlanPDF (plan) {
      // this.$store.dispatch('isLoading', true)
      await this.mostrarPlan(plan) // Esperar a que termine mostrarPlan ya que crear los graficos es async
      // Captura de mapa
      let center = {
        target: [this.incendio.LONGITUD, this.incendio.LATITUD],
        zoom: 16
      }

      try {
        let image = await ArcGIS.captureMap() // En caso de hacer auto-zoom y todo estandarizado ArcGIS.setCenterAndCaptureMap(center)
        this.exportarPlanPDFCallBack(image)
      } catch (error) {
        console.error('Error exportando pdf: ', error)
      }
      // this.$eventHub.$emit('setCenterAndCaptureMap', center) // Envia a mapa este mensaje para que el mapa centre y haga la captura. Luego el mapa envia otro mensaje con la imagen
    },

    exportarPlanPDFCallBack (captura) {
      let plan = this.planSelected
      let docDefinition = {
        pageOrientation: 'portrait',
        content: [

        ],
        styles: {
          header: {
            fontSize: 18,
            margin: [0, 0, 0, 10],
            bold: true
          },
          subheader: {
            fontSize: 12
            // bold: true
          },
          quote: {
            italics: true
          },
          small: {
            fontSize: 8
          }
        }
      }
      docDefinition.content.push(constants.headerPDF)
      docDefinition.content.push(

        {
          text: plan.NOMBRE + ' - ' + this.$date.parseDate(plan.FECHA).format('YYYY/MM/DD HH:mm'),
          style: 'header'
        },
        {
          text: plan.DESCRIPCION + '\n\n', style: 'subheader'
        },
        // plan.DESCRIPCION + '\n\n',
        {
          image: captura,
          width: 500
        }
      )
      pdfMake.createPdf(docDefinition).download(plan.NOMBRE + '_' + this.incendio.MUNICIPIO + '_' + this.$date.now().format('YYYY_MM_DD_hh_mm'))
      // this.$store.dispatch('isLoading', false)
    }
  },

  mounted () {
    this.$eventHub.$on('mapImage', this.exportarPlanPDFCallBack)
  }
}
</script>

<style scoped>
</style>
