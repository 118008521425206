import api from '@/api'
import router from '@/router'

import jwtDecode from 'jwt-decode'

const Q = require('q')

const state = {
  idUsuario: null, // NO SE USA

  accessToken: null
}

const getters = {
  idUsuario: (state) => state.idUsuario,

  accessToken: (state) => state.accessToken
}

const mutations = {
  SET_ID_USUARIO (state, idUsuario) {
    state.idUsuario = idUsuario
  },

  SET_ACCESS_TOKEN (state, token) {
    state.accessToken = token
  }
}

const actions = {
  login: async function ({ commit, dispatch }, credentials) {
    let deferred = Q.defer()

    try {
      const response = await api.auth.login(credentials)

      // const { ID_USUARIO, TOKEN } = response.data
      const TOKEN = response.data.token
      commit('SET_ACCESS_TOKEN', TOKEN)

      const { USUARIO, /* PASSWD, */ ROL } = jwtDecode(TOKEN) // TODO: quitar contraseña del token en backend ?
      let usuario = {
        USUARIO: USUARIO,
        // PASSWD: PASSWD, // No es necesario
        ROL: ROL
      }
      dispatch('usuario/setCurrentUsuario', usuario, { root: true })

      deferred.resolve()
    } catch (err) {
      console.error('Error login', err)
      deferred.reject(err)
    }

    return deferred.promise
  },

  logout ({ commit, dispatch }, tokenInvalid) {
    commit('SET_ACCESS_TOKEN', null)
    dispatch('usuario/setCurrentUsuario', null, { root: true })
    this._vm.$socket.disconnect()

    if (!tokenInvalid) {
      router.push('/login')
    } else {
      router.push({ path: 'login', query: { reason: 'token_invalid' } }).catch(() => {})
    }
  },

  setAccessToken ({ commit }, token) {
    commit('SET_ACCESS_TOKEN', token)
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
