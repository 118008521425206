<template>
  <div ref="symbology">
    <v-card
      v-show="showSymbology"
      color="#537582CC"
      dark
      elevation="6"
      width="500px"
      style="position: absolute; right: 70px; bottom: 60px"
    >
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title class="text-symbology">Simbología</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="toggleMinimize">
          <v-icon>
            {{
              minimized ? "mdi-window-maximize" : "mdi-window-minimize"
            }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <!-- color="primaryLight" Color primario opaco para mejor visual de simbolos -->
      <v-carousel v-model="window" hide-delimiters v-show="!minimized" height="300px">
        <v-carousel-item v-for="n in apartados" :key="n" class="px-6">
          <!-- NEW -->
          <v-card-text v-show="window !== 0">
            <!-- Linea -->
            <v-row class="pb-5">
              <v-col v-if="getTipoLinea.length > 0">
                <v-select
                  class="pa-0"
                  v-model="lineSelected"
                  :items="getTipoLinea"
                  label="Tipo línea"
                  hide-details
                >
                  <template #selection="{ item }">
                    <img
                      :src="
                        require(`@/assets/new_simbologia/${item.src}`)
                      "
                      height="40"
                      width="100"
                    >
                  </template>
                  <template #item="{ item }">
                    <img
                      :src="
                        require(`@/assets/new_simbologia/${item.src}`)
                      "
                      height="40"
                      width="100"
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      :class="{activeBTN: activeBtn === `polylineButton`}"
                      icon
                      v-on="on"
                      @click="clickEsriSymbol('polyline')"
                    >
                      <v-icon small>esri-icon-polyline</v-icon>
                    </v-btn>
                  </template>
                  <span>Línea</span>
                </v-tooltip>
              </v-col>

              <!-- Poligono -->
              <v-col v-if="getTipoPoligono.length > 0">
                <v-select
                  class="pa-0"
                  v-model="polygonSelected"
                  :items="getTipoPoligono"
                  label="Tipo poligono"
                  hide-details
                >
                  <template #selection="{ item }">
                    <img
                      :src="
                        require(`@/assets/new_simbologia/${item.src}`)
                      "
                      height="40"
                      width="100"
                    >
                  </template>
                  <template #item="{ item }">
                    <img
                      :src="
                        require(`@/assets/new_simbologia/${item.src}`)
                      "
                      height="40"
                      width="100"
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      :class="{
                        activeBTN: activeBtn === `polygonButton`,
                      }"
                      icon
                      v-on="on"
                      @click="clickEsriSymbol('polygon')"
                    >
                      <v-icon small>esri-icon-polygon</v-icon>
                    </v-btn>
                  </template>
                  <span>Poligono</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <!-- Iconos -->
            <v-row no-gutters>
              <v-col v-for="(item, i) in getFilterSymbology" :key="i">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      :class="{ activeBTN: activeBtn === item.src }"
                      icon
                      v-on="on"
                      @click="paintImageIcon(item)"
                    >
                      <img
                        :src="
                          require(`@/assets/new_simbologia/${item.src}`)
                        "
                        height="35"
                        width="35"
                      >
                    </v-btn>
                  </template>
                  <span>{{ item.label }}</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <!-- Medio externo-->
            <v-row no-gutters>
              <v-col>
                <v-checkbox label="¿Es un medio externo?" v-model="medio_externo" />
              </v-col>
            </v-row>
          </v-card-text>
          <!-- OLD -->
          <v-card-text v-show="window === 0">
            <v-color-picker
              v-model="colorSelected"
              v-show="showSelectedColor"
              light
              dot-size="25"
              hide-inputs
            />

            <v-container>
              <!-- Color y tipo linea -->
              <v-row align="center" justify="center">
                <v-col sm="5">
                  <v-btn tile :color="colorSelectedHex" @click="showSelectedColor = !showSelectedColor">
                    <v-icon v-show="showSelectedColor">mdi-close</v-icon>
                    Color
                  </v-btn>
                </v-col>

                <v-col>
                  <v-select class="pa-0" v-model="lineSelected" :items="tipoLinea" label="Tipo línea" hide-details>
                    <template #selection="{ item }">
                      <img :src="require(`@/assets/simbologia/${item.icon}`)" height="10" width="100">
                    </template>
                    <template #item="{ item }">
                      <img :src="require(`@/assets/simbologia/${item.icon}`)" height="10" width="100">
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <!-- Botones ESRI -->
              <v-row dense>
                <v-col v-for="item in esriButtons" :key="item.id">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn :class="{activeBTN: activeBtn === `${item.id}Button`}" icon v-on="on" @click="clickEsriSymbol(item.id)">
                        <v-icon small>{{ item.icon }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ item.label }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <!-- Iconos -->
              <v-row no-gutters>
                <v-col v-for="(item, i) in symbology" :key="i">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn :class="{activeBTN: activeBtn === item.src}" icon v-on="on" @click="paintImageIcon(item)">
                        <img :src="require(`@/assets/simbologia/${item.src}`)" height="20" width="20">
                      </v-btn>
                    </template>
                    <span>{{ item.label }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <!-- Divisoria/Vaguada -->
              <v-row align="center" justify="center">
                <v-col>
                  <v-select class="pa-0" v-model="divisoriaSelected" :items="symbologyDivisoria" @change="paintImageIcon" label="Divisoria" hide-details>
                    <template #selection="{ item }">
                      <img :src="require(`@/assets/simbologia/${item.src}`)" height="15" width="15">
                    </template>
                    <template #item="{ item }">
                      <img :src="require(`@/assets/simbologia/${item.src}`)" height="15" width="15">
                    </template>
                  </v-select>
                </v-col>

                <v-col>
                  <v-select class="pa-0" v-model="vaguadaSelected" :items="symbologyVaguada" @change="paintImageIcon" label="Vaguada" hide-details>
                    <template #selection="{ item }">
                      <img :src="require(`@/assets/simbologia/${item.src}`)" height="15" width="15">
                    </template>
                    <template #item="{ item }">
                      <img :src="require(`@/assets/simbologia/${item.src}`)" height="15" width="15">
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-carousel-item>
      </v-carousel>
    </v-card>

    <!-- Dialog text symbol -->
    <v-dialog v-model="showDialogText" persistent width="400px">
      <v-card>
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Introducir texto</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pt-4">
          <v-form v-model="isValidText">
            <v-text-field
              v-model="textSelected"
              label="Texto"
              outlined
              :rules="[globalRules.required]"
            />
            <v-text-field
              v-model="sizeSelected"
              label="Tamaño"
              type="number"
              outlined
              :rules="[globalRules.required]"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="red" @click="cancelTextDialog">Cancelar</v-btn>
          <v-btn
            text
            color="green"
            :disabled="!isValidText"
            @click="acceptTextDialog"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar finalizar TODOS los dibujos -->
    <v-snackbar v-model="showSnackBar" button :timeout="-1">
      ¿Añadir dibujos al plan?
      <template #action="{ attrs }">
        <v-btn text color="pink" v-bind="attrs" @click="finishDrawing">
          Aceptar
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Snackbar dibujo actual -->
    <v-snackbar v-model="isDrawing" button :timeout="-1">
      Haz click en el mapa.
      <template #action="{ attrs }">
        <v-btn text color="pink" v-bind="attrs" @click="cancelCurrentDrawing">
          Cancelar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import * as ArcGIS from '../../helpers/ArcGIS'

export default {
  data: () => ({
    apartados: 2,
    window: 1,

    showSymbology: false,
    callback: null,

    minimized: false,
    showSelectedColor: false,
    colorSelected: {},

    showDialogText: false,
    textSelected: '',
    sizeSelected: 16,

    activeBtn: null,

    showSnackBar: false,
    isDrawing: false,

    graphics: [],

    esriButtons: [
      { id: 'point', label: 'Punto', icon: 'esri-icon-radio-checked' },
      { id: 'polyline', label: 'Línea', icon: 'esri-icon-polyline' },
      { id: 'polygon', label: 'Polígono', icon: 'esri-icon-polygon' },
      { id: 'arrow', label: 'Flecha', icon: 'esri-icon-overview-arrow-top-right' },
      { id: 'text', label: 'Texto', icon: 'esri-icon-comment' }
    ],
    isValidText: false,

    tipoLinea: [
      { style: 'solid', icon: 'line_solid.png' },
      { style: 'short-dot', icon: 'line_short-dot.png' },
      { style: 'short-dash', icon: 'line_short-dash.png' },
      { style: 'short-dash-dot-dot', icon: 'line_short-dash-dot-dot.png' }
    ],

    lineSelected: null,
    polygonSelected: null,
    divisoriaSelected: null,
    vaguadaSelected: null,

    medio_externo: false
  }),

  computed: {
    ...mapGetters('map', [
      'symbology',
      'symbologyDivisoria',
      'symbologyVaguada',
      'newSymbology',
      'newLines',
      'newPolygons'
    ]),
    colorSelectedHex () {
      return this.colorSelected.hex
    },
    getFilterSymbology () {
      return this.newSymbology
    },
    getTipoLinea () {
      return this.newLines
    },
    getTipoPoligono () {
      return this.newPolygons
    }
  },

  methods: {
    showPanelSymbology (data) {
      this.showSymbology = true
      this.showSnackBar = true

      this.callback = data.callback // Funcion a la que se va a llamar al finalizar, se recibe por el eventhub y se le envian los graphics
      this.graphics = data.graphics

      ArcGIS.initPlanOperacionesSketchViewModel({
        callbackCreate: this.onDrawCreated,
        callbackUpdate: this.onDrawUpdated,
        callbackDelete: this.onDrawDeleted
      })
      ArcGIS.drawGraphicsPlanOperaciones(this.graphics)
    },

    toggleMinimize () {
      this.minimized = !this.minimized
    },

    clickEsriSymbol (evt) {
      this.isDrawing = true
      this.activeBtn = evt + 'Button'

      if (evt === 'text') {
        this.showTextDialog()
      } else {
        this.paintEsriSymbol(evt)
      }
    },

    paintEsriSymbol (evt) {
      let color = [
        this.colorSelected.rgba.r,
        this.colorSelected.rgba.g,
        this.colorSelected.rgba.b,
        this.colorSelected.rgba.a
      ]

      let event = {
        function: evt,
        color: color,
        text: this.textSelected,
        size: this.sizeSelected,
        type_line: this.lineSelected ? this.lineSelected.style : null,
        type_polygon: this.polygonSelected ? this.polygonSelected.style : null,
        custom: this.window !== 0
      }

      this.paint(event)
    },

    // #region TEXT SYMBOL
    showTextDialog () {
      this.showDialogText = true
      this.textSelected = ''
    },

    acceptTextDialog () {
      this.showDialogText = false
      this.paintEsriSymbol('text')
    },

    cancelTextDialog () {
      this.showDialogText = false
      this.cancelCurrentDrawing()
    },
    // #endregion

    paintImageIcon (symbol) {
      this.isDrawing = true
      this.activeBtn = symbol.src

      let event = {
        function: 'icon',
        src: symbol.img, // TODO: aqui se pasa el BASE64 de la imagen y es lo que se guarda en base de datos, mirar como pasar la url del fichero
        tipo: symbol.type ? symbol.type : undefined,
        medio_externo: this.medio_externo && symbol.position === 13 // Chapuza para no mostrar el cirulo negro en las descargas.
      }

      this.paint(event)
    },

    paint (event) {
      this.isDrawing = true
      ArcGIS.paintEventPlanOperaciones(event)
    },

    // #region CALLBACKS ARCGIS
    onDrawCreated (data) {
      this.isDrawing = false
      this.activeBtn = null
      this.graphics.push(data)
    },
    onDrawUpdated (data) {
      let atributos = data.ATRIBUTOS
      let geometria = data.GEOMETRIA

      let index = this.graphics.findIndex(
        (x) => x.attributes.ID_GRAFICO === atributos.ID_GRAFICO
      ) // TODO: attributes por ATRIBUTOS

      if (index !== -1) {
        this.graphics[index].attributes = atributos // TODO: nombres parametros
        this.graphics[index].coordenadas = geometria // TODO: nombres parametros
      }
    },
    onDrawDeleted (idGrafico) {
      let index = this.graphics.findIndex(
        (x) => x.attributes.ID_GRAFICO === idGrafico
      ) // TODO: attributes por ATRIBUTOS

      if (index !== -1) {
        this.graphics.splice(index, 1)
      }
    },
    // #endregion

    // Cancela el dibujo actual
    cancelCurrentDrawing () {
      this.isDrawing = false
      this.activeBtn = null

      ArcGIS.paintEventPlanOperaciones('cancel')
    },

    // Finaliza el dibujo y lo envia de vuelta
    finishDrawing () {
      this.showSymbology = false
      this.showSnackBar = false
      this.isDrawing = false
      this.activeBtn = null

      ArcGIS.stopPlanOperacionesSketchViewModel()
      this.callback(this.graphics)

      this.callback = null
    }
  },
  watch: {
    showSymbology () {
      this.showSelectedColor = false
    }
  },

  mounted () {
    this.lineSelected = this.getTipoLinea[0]
    this.polygonSelected = this.getTipoPoligono[0]
    this.divisoriaSelected = this.symbologyDivisoria[0]
    this.vaguadaSelected = this.symbologyVaguada[0]

    this.colorSelected.rgba = {
      a: 0.7,
      b: 16,
      g: 16,
      r: 233
    }

    this.$eventHub.$on('showSymbology', this.showPanelSymbology)
  }
}
</script>

<style scoped>
.activeBTN {
  border: 2px solid red;
}
.activeBTNLabel {
  color: red;
}

</style>
