import request from '../request'

export default {
  getMediosAereos: function () {
    return request.get('planificacion_medios_aereos')
  },

  getMediosAereosPlanif: function (data) {
    return request.post('planificacion_medios_aereos/' + data)
  },

  addMediosAereos: function (data) {
    return request.post('planificacion_medios_aereos', data)
  },

  editMediosAereosPlanif: function (data) {
    return request.put('planificacion_medios_aereos/' + data.idIncendio + '/' + data.idMedio, data.plan)
  },

  deletePlanif: function (data) {
    return request.delete('planificacion_medios_aereos/' + data.idIncendio + '/' + data.idMedio)
  },

  setVueltaAgoncillo: function (data) {
    return request.put(`set_vuelta_agoncillo/${data.ID_MEDIO_SECTOR}/${data.AGONCILLO}`)
  },

  addPeriodoDescanso: function (data) {
    return request.post('periodo_descanso/', data)
  }
}
