<template>
  <v-slide-x-reverse-transition>
    <v-card id="layerList" height="calc(100% - 42px)" width="350px" tile v-show="show">
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Capas</v-toolbar-title>
        <v-spacer />
        <v-btn icon x-large class="mx-0" @click="close()">
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card flat class="mt-4 layerItem" v-if="is3D">
        <v-card-text style="font-size: 16px;">
          <div>
            <v-btn icon color="primary" large @click="refreshExaggeration()"><v-icon>mdi-cached</v-icon></v-btn>
            Exageración 3D
          </div>
          <div>
            <v-slider v-model="exageracion" label="" min="0" max="10" step="1" hide-details />
          </div>
        </v-card-text>
      </v-card>

      <v-list dense>
        <vx-layer-expansion :items="item" v-for="(item, i) in layersOrder" :key="i" />
      </v-list>
    </v-card>
  </v-slide-x-reverse-transition>
</template>

<script>
import { mapGetters } from 'vuex'
import LayerExpansion from './LayerExpansion'

import * as ArcGIS from '../../helpers/ArcGIS'

export default {
  components: {
    'vx-layer-expansion': LayerExpansion
  },

  props: {
    show: Boolean
  },

  data: () => ({
    layersOrder: [],
    exageracion: 1
  }),

  watch: {
    show () {
      if (this.show) {
        this.layersOrder = this.sortLayers()

        for (let i = 0; i < this.layersOrder.length; i++) {
          if (!this.layersOrder[i].sublayers) {
            this.layersOrder[i] = {
              title: this.layersOrder[i].title,
              sublayers: typeof (this.layersOrder[i]) === 'object' ? [this.layersOrder[i]] : this.layersOrder[i]
            }
          }
        }
      }
    },

    layers () { // Watch que cuando la visibilidad / opacidad cambia EN LA STORE, lo actualiza en el mapa
      for (let i = 0; i < this.layers.length; i++) {
        let layer = this.layers[i]
        ArcGIS.setVisibilityLayer(layer.id, layer.visible)
        if (layer.type === 'WMS') {
          for (let j = 0; j < layer.sublayers.length; j++) {
            ArcGIS.setVisibilitySubLayer(layer.id, layer.sublayers[j], layer.sublayers[j].visible)
          }
        }
        ArcGIS.setOpacityLayer(layer.id, layer.opacity)
      }
    },
    exageracion () {
      ArcGIS.setExageration3D(this.exageracion)
    }
  },

  computed: {
    ...mapGetters('map', [
      'layers'
    ]),
    is3D () {
      return this.$store.getters['map/isMap3D']
    }
  },

  methods: {
    close () {
      this.$emit('hide')
    },

    sortLayers () {
      let orderLayers = JSON.parse(JSON.stringify(this.layers))
      orderLayers.sort((a, b) => {
        let aOrden = a.posicion
        let bOrden = b.posicion
        return (aOrden > bOrden) ? -1 : 1
      })

      return orderLayers.filter(x => x.addListLayer)
    },

    refreshExaggeration () {
      this.exageracion = 1
    }
  }
}
</script>

<style scoped>
  #layerList {
    z-index: 3;
    overflow-x: hidden;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .layerItem {
    background: #ebebeb;
    border-radius: 10px;
  }

</style>
